import { useEffect, useState } from 'react'
import './contact.scss'
import beach from '../../assets/beachside.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Contact = () => {
    document.title = "Contact Us"
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const submitHandler = event => {
        // Prevent the default form submission behavior
        event.preventDefault();
      
        fetch('https://api.cameracove.com/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, message }),
        })
          .then(response => {
            if (response.ok) {
                setSuccess(true)
            } else {
                setError("Error sending request.")
            }
          })
          .catch(error => {
            setError("Error sending request.")
        });
      };      

  return (
    <div className='contact-wrapper'>
    <div class="contact-page">
    <div class="image-section">
        <LazyLoadImage
            alt="Contact page image"
            src={beach} // use normal <img> attributes as props
            className='beach-image'
        />
    </div>
    <div class="form-section">
        <div className="interior-form">
            <div className="login-header">
                Get Help
            </div>
            <div className="login-subheader">
                Contact us with the below form.
            </div>
            <br></br>
            <form onSubmit={submitHandler}>
                <div className="name input-field">
                    <span className="field-type">Name</span>
                    <input type="text" id="name" name="name" className='text-input' onChange={e => setName(e.target.value)}/>
                </div>
                <div className="email input-field">
                    <span className="field-type">Email</span>
                    <input type="email" id="email" name="email" className='text-input' onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="message input-field">
                    <span className="field-type">Message</span>
                    <textarea id="message" name="message" className='text-input textarea' onChange={e => setMessage(e.target.value)}/>
                </div>
                {error != '' &&
                    <div className="error-text">
                        {error}
                    </div>
                }
                {success == true &&
                    <div className="success-text">
                        Your message has been received. Please await a response.
                    </div>
                }
                <input type="submit" value="Request Help" className='btn-large gradient-bg submit-button-interior' />
            </form> 
        </div>
        
    </div>
        </div>
    </div>
  )
}

export default Contact