import React from 'react'
import './error.scss'
import { useNavigate } from "react-router-dom";

const Unavailable = () => {
    const navigate = useNavigate()
  return (
    <div className='error-wrapper'>
        <div className="error-content">
            <div className="error-header">
                UNAVAILABLE
            </div>
            <div className="sub-text">
                This product seems to be unavailable. It could be sold out or may have been removed.
            </div>
            <div className="below-sub">
                We apologize for the inconvenience.
            </div>
            <div className="back-home">
                <button className="btn-large gradient-bg back-home-btn" onClick={() => navigate('/')}>Back Home</button>
            </div>
        </div>
        
    </div>
  )
}

export default Unavailable