import {useEffect, useState} from 'react'
import './sites.scss'
import adorama from '../../../assets/adorama.png'
import fred from '../../../assets/fred.gif'
import keh from '../../../assets/keh.png'
import reddit from '../../../assets/reddit.png'
import ebay from '../../../assets/ebaylogo.png'
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const Sites = () => {
    const navigate = useNavigate()
    const [total, setTotal] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('https://api.cameracove.com/products/total');
                const data = await response.json();
                setTotal(data.total);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);
  return (
    <div className='sites-wrapper'>
        <div className="process-title">
            Our Supported Sites
        </div>
        <div className="site-list">
            <div className="keh">
                <img src={keh} alt="" className="site-image" />
            </div>
            <div className="fred">
                <img src={fred} alt="" className="site-image" />
            </div>
            <div className="adorama">
                <img src={adorama} alt="" className="site-image" />
            </div>
            <div className="reddit">
                <img src={reddit} alt="" className="site-image" />
            </div>
            <div className="ebay">
                <img src={ebay} alt="" className="site-image" />
            </div>
        </div>
        <div className="product-total-card">
            We currently have <span className="products-total-now">{total}</span> products
        </div>
        <button className="view-button view-all btn gradient-bg" onClick={() => navigate(`/products`, { replace: true })}>View products <KeyboardArrowRightIcon/></button>
    </div>
  )
}

export default Sites