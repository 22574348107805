import React, { useEffect, useState, useRef } from 'react';
import './image.scss'
import Loading from '../../loader/Loader.jsx'
import Connection from '../../error/Connection';

function Image() {
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [connection, setConnection] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const modalWrapper = useRef(null);

 
    const handleClick = (image) => {
      setSelectedImage(image);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };

    useEffect(() => {
        function handleKeydown(event) {
          if (event.key === 'Escape') {
            closeModal();
          }
        }
        window.addEventListener('keydown', handleKeydown);
      
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        }
      }, [closeModal]);
      
    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await fetch('https://api.cameracove.com/images');
          const data = await response.json();
          setImages(Object.values(data.images));
          setLoading(false)
        } catch (error) {
            setLoading(false)
            setConnection(true)
        }
      };
      fetchImages();
    }, []);
  
    return (
      <div id="photos">
          {loading == false && connection == false &&
          <>
            {images.map((image, index) => (
                <div key={index} className='image-container' onClick={() => handleClick(image)} >
                    <img src={image.updatedImage} alt={image.name} className='image-main-gallery'/>
                    <div className="image-details">
                        <div className="user">
                            <img src={image.profilePicture} alt="Profile Picture" className="profile-picture-user" />
                        </div>
                        <div className="information-image-user">
                            <div className="username">
                                {image.username}
                            </div>
                            <div className="location">
                                {image.city}, {image.country}
                            </div>
                        </div>
                    </div>
                </div>
                ))}
          </>
          }
          {loading == true &&
            <Loading />
          }
          {connection == true &&
            <div className="error">
              Error loading gallery
            </div>
          }
        
        {showModal && (
            <div className='modal-wrapper' ref={modalWrapper}>
                <div className="image">
                    <img src={selectedImage.updatedImage} alt={selectedImage.name} className='modal-image'/>
                </div>
                <div className="modal-description">
                    <div className="user-details">
                        <div className="user-image">
                            <img src={selectedImage.profilePicture} alt="" className="profile-picture-user" />
                        </div>
                        <div className="user-information-profile">
                            <div className="username">
                                {selectedImage.username}
                            </div>
                            <div className="date">
                                {selectedImage.date}
                            </div>
                        </div>
                    </div>
                    <div className="image-details">
                        {selectedImage.city}, {selectedImage.country}
                    </div>
                    
                </div>
                <div className="settings">
                    <div className="header-settings">
                        Image Details:
                    </div>
                    <div className="image-settings">
                            <div className="body">
                                {selectedImage.body}
                            </div>
                            <div className="lens">
                                {selectedImage.lens}
                            </div>
                            <div className="f-stop">
                                {selectedImage.fstop}
                            </div>
                    </div>
                </div>
                
                <button onClick={closeModal} className='close-button'>Close</button>
            </div>
        )}
      </div>
    );
  }
  
export default Image;
  
