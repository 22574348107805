import React from 'react'
import ProductAll from '../components/Products/ProductAll'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'

const Deals = () => {
  return (
    <div className='product-page page'>
        <Navbar />
        <section className="header-section product">
          <ProductAll endpoint={"/deals"}  products={"Deals"} />
        </section>
        <Footer />
    </div>
  )
}

export default Deals