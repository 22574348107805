import { useEffect, useState, useRef } from 'react';
import './search.scss'
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../loader/Loader';
const Search = (props) => {
  const category = props.category
  const value = props.value
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  async function search(e) {
    e.preventDefault();
    setLoading(true)
    window.location = `/search?category=${category}&q=${query}`
  }
  const placeholder = props.placeholder
  useEffect(() => {
    if (value) {
      setQuery(placeholder)
    }
  }, [])
  
  return (
    <div className='search-wrapper'>
        <div className="search-contents">
          <button className="search-button search-submit" onClick={search}><SearchIcon /></button>
            <div className="search-input-wrapper">
              {value == false &&
                <input type="text" placeholder={placeholder} className='search-input' onChange={e => setQuery(e.target.value)}/>
              }
              {value == true &&
                <input type="text" value={query} className='search-input' onChange={e => setQuery(e.target.value)}/>
              }
            </div>
        </div>
        {loading == true &&
          <Loader />
        }
    </div>
  )
}

export default Search