import React from 'react'
import './items.scss'
import lenses from '../../../assets/cameraside.jpg'
import suggestions from '../../../assets/gear.jpg'
import gear from '../../../assets/mountains.jpg'
import { NavLink, useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const ItemCards = () => {
  const navigate = useNavigate()
  return (
    <div className='item-card-welcome-wrapper'>
      <div className="section-header">
        <div className="header">
            See what we offer
        </div>
        <div className="explore-content">
          Explore all of our used products <button className="view-button btn gradient-bg" onClick={() => navigate(`/products`, { replace: true })}>View products <KeyboardArrowRightIcon/></button>
        </div>
      </div>
      <div className="cards">
        <div className="card" onClick={() => navigate(`/cameras`, { replace: true })}>
              <img src={gear} alt="" className="card-image" />
              <div className="card-contents">
                <div className="card-header">
                  Buy Camera Bodies
                </div>
                <div className="card-text">
                  We offer a large selection of camera bodies from various brands. Whether you are a beginner, or an expert - Camera Cove will have something for you! Make sure to check out our particular Cameras section to find only cameras.
                </div>
              </div>
          </div>
          <div className="card" onClick={() => navigate(`/lenses`, { replace: true })}>
              <img src={lenses} alt="" className="card-image" />
              <div className="card-contents">
                <div className="card-header">
                  Buy Lenses
                </div>
                <div className="card-text">
                  Camera Cove offers a large number of brands and types of lenses. Make good use of our filtering system in order to find which lens works best for you!
                </div>
              </div>
          </div>
          <div className="card" onClick={() => navigate(`/suggestions`, { replace: true })}>
              <img src={suggestions} alt="" className="card-image" />
              <div className="card-contents">
                <div className="card-header">
                  Gear Recommendations
                </div>
                <div className="card-text">
                  <a href='/register' className='register-link'>Register</a> to get personalized gear recommendations from our brand new AI!
                </div>
              </div>
          </div>
      </div>
        
    </div>
  )
}

export default ItemCards