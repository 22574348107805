import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Article from './article/Article.jsx'
import Loader from '../loader/Loader.jsx'
import './suggestions.scss'
import SearchBlog from './search/SearchBlog.jsx';
import Content from './right/Content.jsx';
import Connection from '../error/Connection.jsx';

const Suggestions = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [error, setError] = useState(false);
    const [none, setNone] = useState(false);
    const [filtered, setFilter] = useState(false);
    const [date, setDate] = useState('');
    const [posts, setPosts] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [viewed, setViewed] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    document.title = "Suggestions";

    const handleSearchResults = (results) => {
        if (results.length == 0) {
            setNone(true)
        }else {
            setSearchResults(results);
            setSearch(true)
            setNone(false)
        }

    }
    const handleFilterByDate = (event) => {
        const postsArray = Object.values(posts);
        const selectedFilter = event.target.value;
        setDate(selectedFilter)
        // Get current date
        const currentDate = new Date();
      
        let filteredData;
        if (selectedFilter === "day") {
          // Filter posts that are within the last 24 hours
          filteredData = postsArray.filter(post => {
            const postDate = new Date(post.date);
            return postDate > currentDate - 24 * 60 * 60 * 1000;
          });
          if (filteredData.length == 0) {
              setNone(true)
          }
          else {
            setNone(false)
          }
        } else if (selectedFilter === "week") {
          // Filter posts that are within the last 7 days
          filteredData = postsArray.filter(post => {
            const postDate = new Date(post.date);
            return postDate > currentDate - 7 * 24 * 60 * 60 * 1000;
          });
          if (filteredData.length == 0) {
              setNone(true)
          }
          else {
            setNone(false)
          }
        } else if (selectedFilter === "month") {
          // Filter posts that are within the last 30 days
          filteredData = postsArray.filter(post => {
            const postDate = new Date(post.date);
            return postDate > currentDate - 30 * 24 * 60 * 60 * 1000;
          });
          if (filteredData.length == 0) {
              setNone(true)
          }
          else {
            setNone(false)
          }
        } else if (selectedFilter === "6 months") {
          // Filter posts that are within the last 6 months
          filteredData = postsArray.filter(post => {
            const postDate = new Date(post.date);
            return postDate > currentDate - 6 * 30 * 24 * 60 * 60 * 1000;
          });
          if (filteredData.length == 0) {
              setNone(true)
          }
          else {
            setNone(false)
          }
        }
        // Set the filtered data as the new posts array
        setFilteredData(filteredData);
        setFilter(true)
      };
      
      
    useEffect(() => {
        setLoading(true);
      
        const fetchData = async () => {
          try {
            const response = await fetch('https://api.cameracove.com/get-blog-posts', {
              method: 'GET',
              credentials: 'include',
              cache: 'force-cache' // This will cache the response data and images
            });
            const data = await response.json();
            if (data.error) {
              setLoading(false)
              setNone(true);
              return;
            }
            if (response.status !== 200) {
              setError(true)
              setLoading(false);
              return;
            }
            setPosts(data.blogPosts);
            setViewed(data.mostViewedPosts);
            setLoading(false);
          } catch (error) {
            setLoading(false)
            setError(true);
          }
        };
        fetchData();
      }, []);
      
    
      
  return (
    <div className='suggestions-wrapper'>
        {loading == false && error == false &&
        <>
            <div className="filter-wrapper-all">
            <div className="search-wrapper-blog">
                <SearchBlog onSearchResults={handleSearchResults}/>
            </div>
            <div className="filter-date">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Date</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={date}
                    label="Date"
                    onChange={handleFilterByDate}
                  >
                    <MenuItem value={'day'}>Today</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                    <MenuItem value={'month'}>Month</MenuItem>
                    <MenuItem value={'6 months'}>6 Months</MenuItem>

                  </Select>
                </FormControl>
              </Box>
            </div>
            </div>
            
            <div className="main-wrapper">
                <div className="article-main">
                    <div className="articles-title">
                        Articles
                    </div>
                    <hr />
                    {none == true && 
                        <div className="article-wrapper">
                            <div className="none">
                                No Posts Found.
                            </div>
                        </div>
                        
                    }
                    {filtered == false &&
                    <>
                        {search == false && none == false &&
                            <div className="article-wrapper">
                                <Article data={posts} edit={false}/>
                            </div>
                        }
                        {search == true && none == false &&
                            <div className="article-wrapper">
                                <Article data={searchResults} edit={false}/>
                            </div>
                        }
                    </>
                    }
                    
                    {filtered == true &&
                    <>
                        {search == false &&
                            <div className="article-wrapper">
                                <Article data={filteredData}/>
                            </div>
                        }
                        {search == true && none == false &&
                            <div className="article-wrapper">
                                <Article data={filteredData}/>
                            </div>
                        }
                    </>
                    }
                </div>
                <div className="right-content-article">
                    <Content viewed={viewed}/>
                </div>
            </div>
            
        </>
        }
        {loading == true &&
            <Loader />
        }
        {error == true &&
            <Connection />
        }
        
    </div>
  )
}

export default Suggestions