import { useEffect, useState, useRef } from 'react';
import image from '../../assets/reset.jpg'
import { CircularProgress } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './login.scss'
const Reset = () => {
    document.title = "Reset Password"
    const [email, setEmail] = useState('');
    const [view, setView] = useState('verification');
    const [password, setPassword] = useState('');
    const [verification, setVerification] = useState('');
    const [validating, setValidating] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [inputType, setInputType] = useState('password');

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const handleInputChange = (event, nextInputRef) => {
        setVerification(verification + event.target.value);
        const value = event.target.value;
        // Check if the value is not empty
        if (value) {
        // Move to the next input field
        switch (event.target.name) {
            case 'input1':
            input2Ref.current.focus();
            break;
            case 'input2':
            input3Ref.current.focus();
            break;
            case 'input3':
            input4Ref.current.focus();
            break;
        }
        }
    };

    function toggleInputType() {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    }
    const verificationCode = async (event) => {
        event.preventDefault();
        setValidating(true)
        try {
            const response = await fetch(`https://api.cameracove.com/send-verification-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }), // send email and password as properties of the body object
            });
            if (response.status === 200) {
                const data = await response.json();
                setValidating(false)
                setView('code');
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false);
            }
        } catch (error) {
            setError(error.error);
            setValidating(false);
        }
    };
    const validateCode = async (event) => {
        event.preventDefault();
        setValidating(true)
        try {
            const response = await fetch(`https://api.cameracove.com/validate-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: verification, email: email }), // send email and password as properties of the body object
            });
            if (response.status === 200) {
                const data = await response.json();
                setValidating(false)
                setView('form');
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false);
            }
        } catch (error) {
            setError(error.error);
            setValidating(false);
        }
    };

    const resetPassword = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`https://api.cameracove.com/reset-password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, password: password, verificationCode: verification }), // send email and password as properties of the body object
            });
            if (response.status === 200) {
                const data = await response.json();
                setValidating(false);
                setSuccess('Password has been changed.');
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false);
            }
        } catch (error) {
            setError(error.error);
            setValidating(false);
        }
    };

    return (
        <div className='reset-password'>
            <div className='login-wrapper'>
                <div className="login">
                    <div className="login-left">
                        <div className="login-header">
                            Password Reset
                        </div>
                        <div className="login-subheader">
                            Reset your password below.
                        </div>
                        <div className="login-form">
                            {view == 'verification' &&
                                <form onSubmit={verificationCode}>
                                    <div className="email">
                                        <span className="field-type">Email</span>
                                        <input type="email" name="email" placeholder='Enter email' className='text-input' value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                    {error != '' &&
                                        <div className="error-text">
                                            {error}
                                        </div>
                                    }
                                    {validating === false &&
                                        <input type="submit" value="Get Code" className='btn-large sign-submit gradient-bg' />
                                    }
                                    {validating === true &&
                                        <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating' /></button>
                                    }
                                </form>
                            }
                            {view == 'code' &&
                                <form onSubmit={validateCode}>
                                    <span className="field-type">Enter Verification Code</span>
                                    <div className="verification-code">
                                        <div className="code-wrapper">
                                            <input className='code-input' name="input1" maxLength={1} onChange={event => handleInputChange(event, input1Ref)} ref={input1Ref} />
                                            <input className='code-input' name="input2" maxLength={1} onChange={event => handleInputChange(event, input2Ref)} ref={input2Ref} />
                                            <input className='code-input' name="input3" maxLength={1} onChange={event => handleInputChange(event, input3Ref)} ref={input3Ref} />
                                            <input className='code-input' name="input4" maxLength={1} onChange={event => handleInputChange(event, input4Ref)} ref={input4Ref} />
                                        </div>
                                        {error != '' &&
                                            <div className="error-text">
                                                {error}
                                            </div>
                                        }
                                        {validating === false &&
                                            <input type="submit" value="Verify Code" className='btn-large sign-submit gradient-bg' />
                                        }
                                        {validating === true &&
                                            <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating' /></button>
                                        }
                                    </div>
                                </form>
                            }
                            {view == 'form' &&
                                <form onSubmit={resetPassword}>
                                    <div className="password">
                                        <span className="field-type">New Password</span>
                                        <div className="view-password">
                                            <input type={inputType} placeholder='Enter password' className='text-input password-input' value={password} onChange={(event) => setPassword(event.target.value)} /><RemoveRedEyeIcon className='view-icon' onClick={toggleInputType} />
                                        </div>
                                    </div>
                                    {error != '' &&
                                        <div className="error-text">
                                            {error}
                                        </div>
                                    }
                                    {success != '' &&
                                        <div className="success-text">
                                            <div className="sign-up">
                                                <div className="register">
                                                    <a href="/login" className='sign-up-text success-text'>Now, Login Here</a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {validating === false &&
                                        <input type="submit" value="Reset" className='btn-large sign-submit gradient-bg' />
                                    }
                                    {validating === true &&
                                        <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating' /></button>
                                    }
                                </form>
                            }

                        </div>

                    </div>
                    <div className="login-right">
                        <LazyLoadImage
                            alt="Login page image"
                            src={image} // use normal <img> attributes as props
                            className='login-image'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reset