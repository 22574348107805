import {useEffect, useState} from 'react'
import  Skeleton from '@mui/material/Skeleton'
import './ai.scss'
import Cookies from 'js-cookie'
import Card from '../Products/Card/Card'
import { set } from 'date-fns'
const AI = () => {
    const [loading, setLoading] = useState(false)
    const [received, setReceived] = useState(false)
    const [query, setQuery] = useState('')
    const [mode, setMode] = useState('camera')
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [errorMess, setErrorMess] = useState('')

    document.title = "AI Recommendations"
    useEffect(() => {
        try {
            fetch('https://api.cameracove.com/auth/check', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${Cookies.get('auth')}`,
                }
            }
            ).then(response => {
                if (response.ok) {
                    return response
                } else {
                    window.location.href = '/login'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.user === "logged in")
                    return
                else
                    window.location.href = '/login'
            })
        }
        catch (error) {
            console.log(error)
            setError(true)
            setErrorMess('An error has occurred. Please try again later.')
        }
    }, [])


    const getRecommendations = (query) => {
        setLoading(true)
        try{
        // use fetch get 127.0.0.1:5000 as post with input as json with query
        fetch('https://ai.cameracove.com/recommend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({input: query})
        })
        .then(response => {
            if (response.status === 429) {
                setError(true)
                setLoading(false)
                setErrorMess('You have reached your limit on the free tier. Please upgrade plans.')
            }
            if (response.ok) {
                return response
            } else {
                setError(true)
                setLoading(false)
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data)
            setReceived(true)
            setLoading(false)
        }
        )
        } catch (error) {
            setError(true)
            setLoading(false)
            setErrorMess('An error has occurred. Please try again later.')
        }


    }

    const getLensRecommendation = (query) => {
        setLoading(true)
        try{
        // use fetch get 127.0.0.1:5000 as post with input as json with query
        fetch('https://ai.cameracove.com/recommend-lens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({input: query})
        })
        .then(response => {
            if (response.status === 429) {
                setError(true)
                setLoading(false)
                setErrorMess('You have reached your limit on the free tier. Please upgrade plans.')
            }
            if (response.ok) {
                return response
            } else {
                setError(true)
                setLoading(false)
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data)
            setReceived(true)
            setLoading(false)
        }
        )
        } catch (error) {
            setError(true)
            setLoading(false)
            setErrorMess('An error has occurred. Please try again later.')
        }
    }

    const changeMode = () => {
        if (mode === 'camera') {
            setMode('lens')
        } else {
            setMode('camera')
        }
    }
  return (
    <div className='ai-wrapper'>
        <h3>AI Recommendations</h3>
        <div className="toggle-mode">
            <label htmlFor="mode">Mode:</label>
            <button className='toggle-mode-button btn-large gradient-bg-blue' onClick={() => changeMode()}>{mode.toUpperCase()}</button>
        </div>
        {mode === 'camera' &&
        <>
            <p className='subtitle-ai'>Ask our AI for recommendations on the best camera for your needs. <b>As we are in beta, you will be limited to 5 requests. Some errors and bugs may occur.</b></p>
            <div className="h4">Tips:</div>
            <ul>
                <li>Be as specific as possible</li>
                <li>Include your budget</li>
                <li>Include what you will be using the camera for</li>
            </ul>
            <div className="ai-input">
                <textarea type="text" className="ai-input-text" placeholder='I am looking for a camera in the price range of 800-1200 for landscape photography and portraits.'
                onChange={(e) => setQuery(e.target.value)}
                />
                <button className="ai-input-button btn-large gradient-bg" onClick={() => getRecommendations(query)}>Submit</button>
            </div>
            {loading === false && received &&
            <>
                <div className="ai-output">
                    <h2>We Recommend: <span className="gradient-text">{data.name}</span></h2>
                    <div className="reasoning">
                        <h3>Reasoning</h3>
                        <p>{data.reasoning}</p>
                    </div>
                    <div className="stats">
                        <h3>Description</h3>
                        <p>{data.description}</p>
                    </div>
                </div>
                <div className="similar-products">
                {data.similar_products.results.length >= 1 &&
                <>
                    <h3>Buy It Here</h3>
                    <div className="similar-products-wrapper">
                            <Card products={data.similar_products.results} />
                    </div>
                </>
                }
                </div>
            </>
            }
        </>
        }
        {mode === 'lens' &&
        <>
            <p className='subtitle-ai'>Ask our AI for recommendations on the best lens for your needs. <b>As we are in beta, you will be limited to 5 requests. Some errors and bugs may occur.</b></p>
            <div className="h4">Tips:</div>
            <ul>
                <li>Be as specific as possible</li>
                <li>Include your budget</li>
                <li>Include what you will be using the lens for</li>
                <li>Make sure to include the system/brand</li>
            </ul>
            <div className="ai-input">
                <textarea type="text" className="ai-input-text" placeholder='I am looking for a lens in the price range of 800-1200 for landscape photography. I am using a Canon EOS R.'
                onChange={(e) => setQuery(e.target.value)}
                />
                <button className="ai-input-button btn-large gradient-bg" onClick={() => getLensRecommendation(query)}>Submit</button>
            </div>
            {loading === false && received &&
            <>
                <div className="ai-output">
                    <h2>We Recommend: <span className="gradient-text">{data.name}</span></h2>
                    <div className="reasoning">
                        <h3>Reasoning</h3>
                        <p>{data.reasoning}</p>
                    </div>
                    <div className="stats">
                        <h3>Description</h3>
                        <p>{data.description}</p>
                    </div>
                </div>
                <div className="similar-products">
                {data.similar_products.results.length >= 1 &&
                <>
                    <h3>Buy It Here</h3>
                    <div className="similar-products-wrapper">
                            <Card products={data.similar_products.results} />
                    </div>
                </>
                }
                </div>
            </>
            }
        </>
        }
         {loading &&
            <>
                <div className="skeleton-wrapper">
                    <Skeleton variant="h2" width="80vw" height={30} />
                    <div className="skeleton-reasoning">
                        <Skeleton variant="h2" width="80vw" height={30} />
                        <Skeleton variant="rectangular" width="80vw" height={118} />
                    </div>
                    <div className="skeleton-stats">
                        <Skeleton variant="h2" width="80vw" height={30} />
                        <Skeleton variant="rectangular" width="80vw" height={118} />
                    </div>
                </div>
            </>
            }
        {error &&
        <>  
            <div className="error">
                <h2>{errorMess}</h2>
            </div>
        </>
        }
            
    </div>
  )
}

export default AI