import React from 'react';
import { CircularProgress } from '@mui/material';
import './loader.scss'
function Loader() {
  return (
      <div className="loader-wrapper">
            <div className="loader">
                <CircularProgress
                style={{'color': '#FA8071'}}
                />
            </div>
      </div>
    
  );
}


export default Loader