import React, { useState, useEffect } from 'react';
import './searchblog.scss'
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../../loader/Loader';

const SearchBlog = (props) => {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    async function searchPosts(query) {
        setLoading(true)
        try {
          const response = await fetch(`https://api.cameracove.com/search-blog-posts?q=${query}`);
          const data = await response.json();
          setLoading(false)
          props.onSearchResults(data.results);
        } catch (error) {
          console.error(error);
          setLoading(false)
        }
      }
  return (
    <div className='search-wrapper'>
        <div className="search-contents">
          <button className="button search-submit" onClick={() => searchPosts(query)}><SearchIcon /></button>
            <div className="search-input-wrapper">
              <input type="text" placeholder="Find a post" className='search-input' value={query} onChange={(event) => setQuery(event.target.value)}/>
            </div>
        </div>
        {loading == true && 
            <Loader />
        }
    </div>
  )
}

export default SearchBlog