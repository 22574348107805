// SellerDashboard.jsx
import React, { useEffect, useState } from 'react';
import './sellerdashboard.scss';
import { useParams } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import EditProductModal from '../seller/EditProductModal';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';
import Loader from '../loader/Loader';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Tooltip } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    Box,
    Avatar, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { set } from 'date-fns';
const SellerDashboard = () => {
    const [details, setDetails] = useState([]);
    const [listedItems, setListedItems] = useState([]);
    const [completedItems, setCompletedItems] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [productIdToEdit, setProductIdToEdit] = useState('');
    const [itemId, setItemId] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const sellerId = window.location.pathname.split('/')[3];

    document.title = 'Seller Dashboard';

    const fetchListedItems = async () => {
        try {
            const response = await fetch(`https://api.cameracove.com/seller-listed-items/${sellerId}`);
            const { items } = await response.json();
            console.log(items);
            if (items != undefined) {
                setListedItems(items);

                // Count the number of completed items
                let completedCount = 0;
                let totalRevenue = 0.;
                for (const item of items) {
                    if (item.available.toLowerCase() === 'completed') {
                        completedCount++;
                        totalRevenue += parseInt(item.price);
                    }
                }
                setTotalRevenue(totalRevenue.toFixed(2));
                setCompletedItems(completedCount);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching listed items:', error);
        }
    };

    const fetchSellerData = async () => {
        try {
            const response = await fetch(`https://api.cameracove.com/seller/${sellerId}`);
            const data = await response.json();

            if (!data) {
                console.error('User is not a seller');
                return;
            }
            setDetails(data.seller);
            setReviews(data.seller.reviews);

        } catch (error) {
            console.error('Error fetching seller data:', error);
        }
    };

    const updateStatus = async (productId, status) => {
        try {
            // Post to the /update-product route with the productID and the availability status which should be completed here
            const response = await fetch(`https://api.cameracove.com/update-product-status`, {
                body: JSON.stringify({
                    productId: productId,
                    availabilityStatus: status,
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${Cookies.get('auth')}`,
                },
            });
            const data = await response.json();
            setSuccess(true);
            setOpen(true);
            setMessage("Product has been updated, status: " + data.status);
            if (data.status === 'Completed') {
                fetchListedItems();
                fetchSellerData();
            }
            else {
                fetchListedItems();
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        setLoading(true);
        fetchListedItems();
        fetchSellerData();
    }, [sellerId]);

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating - fullStars >= 0.5;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarIcon key={i} />);
        }

        if (hasHalfStar) {
            stars.push(<StarHalfIcon key={fullStars} />);
        }

        return stars;
    };

    const handleViewClick = (productId) => {
        setProductIdToEdit(productId);
        setItemId(productId);
        setIsModalOpen(true);
    };

    const handleDelete = async (productId) => {
        try {
            await fetch(`https://api.cameracove.com/product/us/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${Cookies.get('auth')}`
                },
            });
            window.location.reload();
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };



    return (
        <div className="container">
            {loading && <Loader />}
            {!loading &&
                <>
                    <div className="header">
                        <div className="seller-details">
                            <div className="name">
                                <h2>Welcome, {details.name}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="tiles">
                        <div className="tile">
                            <h3>Total Listed Items</h3>
                            <p className='value-int'>{listedItems.length}</p>
                        </div>
                        <div className="tile">
                            <h3>Total Completed Items</h3>
                            <p className='value-int'>{completedItems}</p>
                        </div>
                        <div className="tile">
                            <h3>Total Revenue</h3>
                            <p className='value-int'>${totalRevenue}</p>
                        </div>
                    </div>
                    <div className="items-seller">
                        <div className="products">
                            {listedItems.map((item) => (
                                <div key={item.itemId} className="product-card">
                                    <img src={item.imageUrls[0]} alt={item.title} />
                                    <div className="item-details">
                                        <h2>{item.name}</h2>
                                        <p>${item.price}</p>
                                        <p>{item.available}</p>
                                        <div className="seller-actions">
                                            <div className="update-status">
                                                <Tooltip title="Delete">
                                                    <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(item.product_id, 'Pending')}>
                                                        <DeleteIcon />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Mark Available">
                                                    <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(item.product_id, 'Available')}>
                                                        <ShoppingBagIcon />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Mark Pending">
                                                    <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(item.product_id, 'Pending')}>
                                                        <AccessTimeIcon />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Mark as Completed">
                                                    <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(item.product_id, 'Completed')}>
                                                        <CheckCircleIcon />
                                                    </button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                    </div>
                                    <button className="view-button-dashboard" onClick={() => handleViewClick(item.product_id)}>
                                        <RemoveRedEyeIcon />
                                    </button>
                                    <button className="delete-button" onClick={() => handleDelete(item.product_id)}><DeleteIcon /></button>

                                </div>
                            ))}
                        </div>
                        <div className="reviews-dashboard">
                            <h2>Reviews</h2>
                            {reviews &&
                                <>
                                    <div className="reviews-dashboard-seller">
                                        {reviews.map((review) => (
                                            <div className="review-wrapper review-dashboard all-reviews">
                                                <div className="review-header">
                                                    <div className="reviewer-details">
                                                        <img src={review.reviewerProfileImage} alt="Profile picture" className="profile-image" />
                                                        <div className="name">
                                                            <h2>{review.reviewerName}</h2>
                                                        </div>
                                                    </div>
                                                    <Box display="flex" alignItems="center">
                                                        {renderStars(review.rating)}
                                                        <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                                            {review.rating}
                                                        </Typography>
                                                    </Box>
                                                    <div className="review-description">
                                                        <p className="review-text">{review.review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </>
                            }

                        </div>
                        <div className="sell-another">
                            <h2>Sell Another Item</h2>
                            <button className='btn-large sign-submit gradient-bg' onClick={
                                () => {
                                    window.location.href = '/sell-product';
                                }
                            }>Sell a Product</button>
                        </div>
                        <div className="view-conversations">
                            <h2>View Conversations</h2>
                            <button className='btn-large sign-submit gradient-bg' onClick={
                                () => {
                                    window.location.href = '/conversations';
                                }
                            }>View Conversations</button>

                        </div>
                    </div>
                </>
            }

            {isModalOpen &&
                <EditProductModal
                    productId={productIdToEdit}
                    sellerId={details.sellerId}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            }
            {success &&
                <Snackbar
                    open={open}
                    autoHideDuration={3500}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            }
        </div>
    );
};

export default SellerDashboard;
