import React from 'react'
import './process.scss'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
const Process = () => {
  return (
    <div className='process-wrapper'>
        {/*
        <div className="headers">
            <div className="section-header">
                The Process
            </div>
            <div className="section-subheader">
                What does this website do? Swipe through and find out!
            </div>
        </div>
        */}
        <Carousel showArrows={true} >
            <div className="item-process">
                <div className="process-title">
                    <CameraAltIcon className='section-icon'/>
                    We find all the used gear
                </div>
                <div className="process-description">
                    We gather products from various used gear sites and combine them here so that you can find the best deal within out modern user-interface.
                </div>

            </div>
            <div className="item-process" data-id="slide-2">
                <div className="process-title">
                    <FindInPageIcon className='section-icon'/>
                    Gather it and display it here
                </div>
                <div className="process-description">
                    We gather all the data from the various sources on a rather routine basis. Whenever we update the products, you may lose some liked items as they are no longer available. We want to ensure that the product-base maintains up-to-date to prevent sold items from being displayed.
                </div>
            </div>
            <div className="item-process" data-id="slide-3">
                <div className="process-title">
                    <ShoppingBagIcon className='section-icon'/>
                    Click a few buttons and it's yours!
                </div>
                <div className="process-description">
                    Once you find the product that you wish to purchase, you can simply click a button to an external link. From there, you are able to purchase the item from the end-site.
                </div>
            </div>
        </Carousel>
    </div>
  )
}

export default Process