import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import PaymentSuccessHandler from '../components/subscription/PaymentSuccessHandler'

const PaymentChecker = () => {
  return (
    <div className='product-page page'>
        <Navbar />
        <section className="header-section subscription">
          <PaymentSuccessHandler />
        </section>
        <Footer />
    </div>
  )
}

export default PaymentChecker