import React from 'react'
import './error.scss'
import { useNavigate } from "react-router-dom";

const Results = () => {
    const navigate = useNavigate()
  return (
    <div className='results'>
        <div className="results-content">
            <div className="results-header">
                0 Results
            </div>
            <div className="sub-text">
                No results were found
            </div>
            <div className="below-sub">
                If you have filters, please remove them and try again.
            </div>
        </div>
        
    </div>
  )
}

export default Results