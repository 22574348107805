import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './conversations.scss';
import Loader from '../loader/Loader';
import DeleteIcon from '@mui/icons-material/Delete';
import { set } from 'date-fns';

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  document.title = 'Conversations';
  useEffect(() => {
    setLoading(true);
    // Fetch the user's conversations when the component mounts
    const fetchConversations = async () => {
      try {
        const response = await fetch('https://api.cameracove.com/conversations', {
          headers: {
            Authorization: `${Cookies.get('auth')}`,
          },
        });
        const data = await response.json();
        setConversations(data.conversations);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchConversations();
  }, []);

  return (
    <div className="conversations">

      <h2>My Conversations</h2>
      {loading &&
        <Loader />
      }
      {conversations.length === 0 && !loading ? (
        <div>No conversations</div>
      ) : (
        <ul className="conversation-list">
          {conversations.map((conversation) => {
            const isCurrentUserSender = conversation.senderId === conversation.uid;

            // Determine the display name and profile image based on the current user's role in the conversation
            const displayName = isCurrentUserSender ? conversation.recipientDisplayName : conversation.senderDisplayName;
            const profileImage = isCurrentUserSender ? conversation.recipientProfileImage : conversation.senderProfileImage;
            return (
              <div className='conversations-wrapper'>
                <div
                  key={conversation.conversationId}
                  className={`conversation-item ${conversation.read ? 'read' : 'unread'}`}
                  onClick={() => {
                    window.location.href = `/chat/${conversation.conversationId}`;
                  }}
                >
                  <div className="item-details">
                    <div className="item-image">
                      <img src={conversation.itemImage} alt="Item" className='item-image-image' />
                    </div>

                  </div>
                  <div className="profile-arranged">
                    <div className="profile-top">
                      <div className="profile-image">
                        <img src={profileImage} alt="Profile" />
                      </div>
                      <div className="details">
                        <h3>{displayName}</h3>
                        <p>{conversation.read ? 'Read' : 'Unread'}</p>
                      </div>
                    </div>

                  </div>

                  <div className="message">
                    {!conversation.lastMessage.status && <p>{conversation.lastMessage}</p>}
                    {conversation.lastMessage.status && <p className="quote">Quote has been created.</p>}
                  </div>
                </div>
                <div className="delete-conversation">
                  <button className='delete-button btn-large gradient-bg' onClick={() => {
                    const deleteConversation = async () => {
                      try {
                        const response = await fetch(`https://api.cameracove.com/conversations/${conversation.conversationId}`, {
                          method: 'DELETE',
                          headers: {
                            Authorization: `${Cookies.get('auth')}`,
                          },
                        });
                        const data = await response.json();
                        console.log(data)
                        if (data.conversations === null) {
                          setConversations([]);
                          return;
                        }

                        setConversations(data.conversations);
                      } catch (error) {
                        console.error(error);
                      }
                    };
                    deleteConversation();
                  }}><DeleteIcon /></button>
                </div>
              </div>
            );
          })}

        </ul>
      )}

    </div>
  );
};

export default Conversations;
