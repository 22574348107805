import React, { useState, useEffect } from 'react';
import Article from '../suggestions/article/Article';
import './upload.scss'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cookies from 'js-cookie';

const Upload = () => {
    const [show, setShow] = useState(true);
    const [posts, setPosts] = useState([]);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [body, setBody] = useState('');
    const [lens, setLens] = useState('');
    const [fstop, setFstop] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [validating, setValidating] = useState(false);
    const [success, setSuccess] = useState(false);
    document.title = "Writer Dashboard"

    const handleChange = (event) => {
        // Get the file from the input
        const newFile = event.target.files[0];

        // Set the file in the state
        setFile(newFile);

        // Create a preview
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(newFile);
    }

    const submitBlog = async (event) => {
        event.preventDefault();
        if (file != null) {
            setValidating(true)
            const formData = new FormData();
            formData.append('city', city);
            formData.append('country', country);
            formData.append('body', body);
            formData.append('lens', lens);
            formData.append('fstop', fstop);
            formData.append('file', file);
            try {
                const response = await fetch('https://api.cameracove.com/upload-image', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                    }
                });

                if (response.status == 200) {
                    const data = await response.json();
                    setSuccess(true)
                } else {
                    const error = await response.json();
                    setError(error.error);
                    setValidating(false)
                    setSuccess(false)
                }
            } catch (error) {
                setError(error.error);
                setValidating(false)
            }
        } else {
            setValidating(false)
            setError("Error. Please upload a profile picture.")
        }
    }
    useEffect(() => {
        const fetchUserPosts = async () => {
            const response = await fetch('https://api.cameracove.com/get-user-images', {
                method: 'GET',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            if (response.status != 200) {
                window.location.href = "/login"
            }
            const data = await response.json();
            setPosts(data.userImages);
        }
        fetchUserPosts();
    }, []);
    return (
        <div className='write-wrapper'>
            <div className="form-header">Post an Image</div>
            {success == false &&
                <div className="write-new-wrapper">
                    <form onSubmit={submitBlog} className='write-form-main'>
                        <div className="file">
                            {preview &&
                                <div className="image-preview-image">
                                    <img src={preview} alt="Preview" className='preview-image-image' />
                                </div>
                            }
                            <span className="field-type">Your Image</span>
                            <input type="file" onChange={handleChange} className='file-upload' />
                        </div>
                        <div className="title">
                            <span className="field-type">Photo City</span>
                            <input type="text" name="city" placeholder='Los Angeles' className='text-input write-input' value={city} onChange={(event) => setCity(event.target.value)} />
                        </div>
                        <div className="title">
                            <span className="field-type">Photo Country</span>
                            <input type="text" name="country" placeholder='United States' className='text-input write-input' value={country} onChange={(event) => setCountry(event.target.value)} />
                        </div>
                        <div className="title">
                            <span className="field-type">Camera Body</span>
                            <input type="text" name="body" placeholder='Sony a7RIII' className='text-input write-input' value={body} onChange={(event) => setBody(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Lens</span>
                            <input type="text" name="lens" placeholder='Sony 70-200 f4' className='text-input write-input' value={lens} onChange={(event) => setLens(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">F-Stop</span>
                            <input type="text" name="lens" placeholder='f7.1' className='text-input write-input' value={fstop} onChange={(event) => setFstop(event.target.value)} />
                        </div>
                        {validating === false &&
                            <input type="submit" className="btn-large gradient-bg submit-button" placeholder='Submit Article' />
                        }
                        {validating === true &&
                            <button className="btn-large gradient-bg submit-button"><CircularProgress color="inherit" className='validating' /></button>
                        }
                    </form>
                </div>
            }
            {success == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your image has been uploaded. Refresh to view.
                    </div>
                    <button className="btn-large gradient-bg" onClick={() => setSuccess(false)}>Upload Another</button>
                </div>
            }
                <div className="user-posts">
                    {show == true &&
                    <>            
                        <div className="form-header">
                            Your Posts 
                            <Tooltip title="Hide" className='show-hide'>
                                <IconButton>
                                    <HideSourceIcon onClick={() => setShow(false)}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        
                        {posts != null &&
                            <div className="image-wrapper-internal">
                                <div className="image-internal">
                                    <Article data={posts} type={"image"} edit={true} className='article' />
                                    <hr />
                                </div>
                            </div>
                        }
                    </>
                    }
                    {show == false &&
                        <div className="show-header">
                            <Tooltip title="Show Posts" className='show-hide'>
                                <IconButton>
                                    <VisibilityIcon onClick={() => setShow(true)}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </div>

        </div>
    )
}

export default Upload