import React, { useState } from 'react';
import { Select, MenuItem, Tooltip, FormControl, InputLabel } from '@mui/material';
import { CircularProgress } from '@mui/material';
import './sellerform.scss'
import Cookies from 'js-cookie';

const SellerForm = () => {
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState('');
    const [condition, setCondition] = useState('');
    const [shippingPrice, setShippingPrice] = useState('');
    const [notes, setNotes] = useState('');
    const [state, setState] = useState('');
    const [name, setName] = useState('');
    const [brand, setBrand] = useState('');
    const [type, setType] = useState('');
    const [previewImages, setPreviewImages] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validating, setValidating] = useState(false);

    const conditionDescriptions = {
        'New Sealed': 'Brand new product in original sealed package',
        'Excellent': 'Product in near-mint condition, with no visible flaws',
        'Very Good': 'Product in very good condition, with minor flaws',
        'Good': 'Product in good condition, with some flaws',
        'Poor': 'Product in poor condition, with significant flaws',
        'Malfunctioning': 'Product is not working properly',
        'Parts': 'Product is being sold for parts',
        'Open Box': 'Product is in good condition, but the box has been opened'
    };

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
        'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
        'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
        'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
        'Wisconsin', 'Wyoming'
    ];


    const handleSubmit = async (event) => {
        event.preventDefault();
        setValidating(true);
        // check inputs to make sure they are all valid
        // if not, display error message
        // if valid, send data to server
        if (name === '') {
            alert('Please enter a name');
            return;
        }
        if (images.length === 0) {
            alert('Please upload at least one image');
            return;
        }
        if (price === '' || price <= 0) {
            alert('Please enter a price');
            return;
        }
        if (condition === '') {
            alert('Please select a condition');
            return;
        }
        if (shippingPrice === '' || shippingPrice <= 0) {
            alert('Please enter a shipping price');
            return;
        }
        if (notes === '') {
            alert('Please enter some notes');
            return;
        }

        try {
            const formData = new FormData();
            images.forEach((image, index) => {
                formData.append('images', image, `image-${index}`);
            });
            formData.append('name', name);
            formData.append('price', parseFloat(price).toFixed(2));
            formData.append('condition', condition);
            formData.append('brand', brand)
            formData.append('type', type);
            formData.append('shippingPrice', parseFloat(shippingPrice).toFixed(2));
            formData.append('notes', notes);
            formData.append('state', state);
    
            const response = await fetch('https://api.cameracove.com/post-product', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
    
            if (!response.ok) {
                setError(error);
                setValidating(false);
            }
    
            const data = await response.json();
            setSuccess(true);
            setValidating(false);
        } catch (error) {
            console.error(error);
            setError(error);
            setValidating(false);
        }
    };    

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setImages(files);

        const previewImages = files.map(file => {
            return URL.createObjectURL(file);
        });
        setPreviewImages(previewImages);
    };


    return (
        <div className="seller-form">
            <form onSubmit={handleSubmit}>
                <h2>Post a New Product</h2>
                <div className="form-content">
                    <div className="image-upload">
                        <div className="label-form">
                        Images:
                        </div>
                        <div>
                            <input type="file" className='file-upload' multiple onChange={handleImageUpload} required />
                            <div className="image-preview">

                                {previewImages.map((src, index) => (
                                        <img key={index} src={src} alt="Preview" width={150} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="item-wrapper-input">
                        <div className="label-form">
                            Item Name:
                        </div>
                        <div className="input-seller-item">
                            <input type="text" className="seller-input" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="item-wrapper-input">
                        <div className="label-form">
                            Price:
                        </div>
                        <div className="input-seller-item">
                            <input type="number" className="seller-input" value={price} onChange={(e) => setPrice(e.target.value)} required />
                        </div>
                    </div>
                    <div className="item-wrapper-input">
                        <div className="label-form">
                            Brand:
                        </div>
                        <div className="input-seller-item">
                            <input type="text" className="seller-input" value={brand} onChange={(e) => setBrand(e.target.value)} required />
                        </div>
                    </div>
                    <FormControl>
                        <InputLabel id="condition-label">Item Type</InputLabel>
                        <div className="select-component">
                            <Select
                                labelId="condition-label"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                required
                                className='select-condition'
                            >
                                {['Camera', 'Lens', 'Accessory'].map((conditionOption) => (
                                        <MenuItem value={conditionOption}>{conditionOption}</MenuItem>
                                ))}
                            </Select>
                           
                        </div>
                       
                    </FormControl>
                    <FormControl>
                        <InputLabel id="condition-label">Condition</InputLabel>
                        <div className="select-component">
                            <Select
                                labelId="condition-label"
                                value={condition}
                                onChange={(e) => setCondition(e.target.value)}
                                required
                                className='select-condition'
                            >
                                {['New Sealed', 'Excellent', 'Very Good', 'Good', 'Poor', 'Malfunctioning', 'Parts', 'Open Box'].map((conditionOption) => (
                                        <MenuItem value={conditionOption}>{conditionOption}</MenuItem>
                                ))}
                            </Select>
                            {condition !== '' &&
                                <Tooltip title={conditionDescriptions[condition]} placement="right">
                                    <div className="condition-description">
                                        ?
                                    </div>
                                </Tooltip>
                            }
                        </div>
                       
                    </FormControl>

                   


                    <div className="item-wrapper-input">
                        <div className="label-form">
                            Shipping Price:
                        </div>
                        <div className="input-seller-item">
                            <input type="number" className="seller-input" value={shippingPrice} onChange={(e) => setShippingPrice(e.target.value)} required />
                        </div>
                    </div>

                    <div>
                        <div className="label-form">
                            Notes:
                        </div>
                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} required />
                    </div>

                    <FormControl>
                            <InputLabel id="condition-label">State</InputLabel>
                            <Select
                            className='select-component'
                                labelId="condition-label"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                required
                            >
                                {states.map((stateOption) => (
                                    <MenuItem value={stateOption}>{stateOption}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    {validating === false &&
                        <button type="submit" className='btn-large sign-submit gradient-bg'>Submit</button>
                    }
                    {validating === true &&
                        <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating'/></button>
                    }
                </div>
                
            </form>
            {success &&
                <div className="success-message">
                    Product successfully posted!
                </div>
            }
            {error &&
                <div className="error-message">
                    Error posting product
                </div>
            }
        </div>
        

    );
};

export default SellerForm;
