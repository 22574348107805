import React from 'react'
import Register from '../components/Login/Register'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Terms from '../components/terms/Terms'
import Upload from '../components/upload/Upload'

const TermsConditions = () => {
    document.title = "Upload Image"
  return (
    <div className='upload-wrapper'>
        <Navbar />
        <section className="upload-section terms">
          <Upload />
        </section>
        <Footer />
    </div>
  )
}

export default TermsConditions