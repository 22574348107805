import {useEffect} from 'react'
import './about.scss'
import beach from '../../assets/beach.png'
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    document.title = "About"
    return (
        <div className='about-wrapper'>
            <div className="about">
                <div className="about-image">
                    <img src={beach} alt="" className="about-image-inside" />
                </div>
                <div className="about-header">
                    About <br></br><span className="gradient-text">Camera Cove</span>
                </div>
            </div>
            <div className="about-contents">
                Welcome to <span className="bold">Camera Cove</span>! We are a used camera gear comparison website that gathers data from various sources to provide you with the most comprehensive list of used camera gear available.
                <br></br><br></br>
                Our goal is to make it easier for you to search, compare, and find the perfect used camera gear for your needs. With Camera Cove, you can view a wide range of camera gear from different sellers all in one place, making it easy to find the best deals and make informed purchasing decisions.
                <br></br><br></br>
                In addition to our comprehensive list of used camera gear, we also offer pre-made gear suggestions and regularly update our data and products to keep you up-to-date on the latest offerings.
                <br></br><br></br>
                We want to make your used camera gear shopping experience as seamless and hassle-free as possible. That's why we provide all of the necessary information and links for you to view and purchase your desired gear directly from the seller's website. Please note that Camera Cove is simply an intermediary and is not responsible for any issues that may arise with the end seller. All responsibility for the transaction lies with the client and the end-site.
                <br></br><br></br>
                Thank you for choosing Camera Cove for your used camera gear needs. Happy shopping!

            </div>
        </div>
    )
}

export default About