import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import Card from '../Products/Card/Card'
import Loader from '../loader/Loader.jsx'
import { NavLink, useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

import './dashboard.scss'
const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [likes, setLikes] = useState([])
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState(false);
    const navigate = useNavigate()
    document.title = "Liked";

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseToast = () => {
        setToast(false);
    };

    const deleteAllLikes = async () => {
        try {
            const response = await fetch('https://api.cameracove.com/likes', {
                method: 'DELETE',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                setToast(true);
                setTimeout(() => {
                    setToast(false);
                }, 3000);
                setLikes([]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch('https://api.cameracove.com/likes', {
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
                try {
                    if (response.status != 200) {
                        window.location.href = '/login';
                    }
                    const data = await response.json();
                    setLikes(Object.values(data));
                    setLoading(false)
                }catch(error) {
                    setError(null)
                    setLoading(false)
                }
                
            }catch (error) {
                window.location.href = '/login';
                setLoading(false);
              };
        }
        fetchData();
      }, []);
      
    return (
        <div className='dashboard-wrapper'>
            <div className="dashboard">
                {loading === true &&
                    <Loader />
                }
                {loading === false &&
                    <>
                        <div className="likes">
                            <span className="profile-user-header">Likes</span>
                            <div className="delete-likes">
                                <button className='delete-button btn-large gradient-bg' onClick={deleteAllLikes}>Delete All Likes <DeleteIcon /></button>
                            </div>
                            <div className="likes-wrapper">
                                    {likes.length === 0 &&
                                        <div className="no-likes">
                                            <span className="no-likes-text">You have no liked items</span>
                                        </div>
                                    }
                                    <div
                                        className="liked-item"
                                    >
                                        {error != null &&
                                            <Card products={likes} show={true} />
                                        }
                                        
                                    </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}

export default Dashboard