import React, { useEffect, useState } from 'react';
import './payment.scss';
import Cookies from 'js-cookie';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { set } from 'date-fns';
import Loader from '../loader/Loader';

const PaymentSuccessHandler = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const handlePaymentStatus = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const success = urlParams.get('success');
        const type = urlParams.get('type');

        if (success === 'true') {
          // Payment was successful and has a paymentIntentID
          const response = await fetch('https://api.cameracove.com/api/payment-confirmation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${Cookies.get('auth')}`,
            },
            body: JSON.stringify({ type }),
          });

          const data = await response.json();
          console.log(data);
          if (response.ok) {
            // Payment verification successful
            setInvoice(data.item);
            setPaymentStatus('success');
          } else {
            // Payment verification failed
            setPaymentStatus('verification-failed');
          }
        } else {
          // Payment failed or missing paymentIntentID
          setPaymentStatus('failed');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error handling payment status:', error);
        setPaymentStatus('error');
        setLoading(false);
      }
    };

    handlePaymentStatus();
  }, []);

  return (
    <div className='payment-wrapper'>
        {loading && 
            <Loader />
        }
      <div className="main-header-payment">
        {paymentStatus === 'success' && 
        <>
                    <ThumbUpAltIcon className='success-icon' />
            <h2>Payment successful!</h2>
            <span className='subtitle'>Thank you for your purchase!</span>
            <div className="purchase-details">
                {invoice && 
                <>
                    <div className="item-type">
                        Type: <span className="total">
                        {invoice.type} Subscription
                        </span>
                    </div>
                    <div className="item-amount">
                        Total: <span className="total">
                        ${invoice.itemPrice}
                        </span>
                    </div>
                    <div className="item-type">
                        Billing: <span className="total">
                        Monthly
                        </span>
                    </div>
                    <div className="item-type">
                        Next Charge: <span className="total">
                        {invoice.currentPeriodEndDate}
                        </span>
                    </div>
                </>
                }
                
            </div>
        </>
        }
        {paymentStatus === 'verification-failed' && 
        <>
            <CancelIcon className='success-icon' />
            <h2>Payment Failed!</h2>
            <span className='subtitle'>We are sorry, but an error occured while verifying the payment.</span>
         </>
        }
        {paymentStatus === 'failed' && 
            <>
            <CancelIcon className='success-icon' />
            <h2>Payment failed.</h2>
            <span className='subtitle'>We are sorry, but an error occured while verifying the payment.</span>
         </>
        }
        {paymentStatus === 'error' && 
            <>
            <CancelIcon className='success-icon' />
            <h2>Payment error.</h2>
            <span className='subtitle'>We are sorry, but an error occured while verifying the payment.</span>
         </>
        }
      </div>
    </div>
  );
};

export default PaymentSuccessHandler;
