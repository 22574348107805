import React from 'react'
import SellerForm from '../components/seller/SellerForm'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'

const SellerSell = () => {
  return (
    <div className='seller-page page'>
        <Navbar />
        <section className="header-section login">
            <SellerForm />
        </section>
        <Footer />
    </div>
  )
}

export default SellerSell