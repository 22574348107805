import { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/logo-large.png'
import NotificationsIcon from '@mui/icons-material/Notifications';
import './navbar.scss'
import { MenuItem } from '@mui/material';

const Navbar = () => {
  const dropdownRef = useRef(null);
  const [login, setLogin] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const isLocated = location.pathname === '/products' || location.pathname === '/cameras' || location.pathname === '/lenses' || location.pathname === '/accessories' || location.pathname === '/deals';
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < 785);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    // Fetch the unread message count from the backend
    const fetchUnreadCount = async () => {
      try {
        const response = await fetch('https://api.cameracove.com/unread-messages' , {
            headers: {
              Authorization: `${Cookies.get('auth')}`,
            },
          }  
        );
        const data = await response.json();
        setUnreadCount(data.unreadCount);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUnreadCount();

    return () => {
      // Cleanup if necessary
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      setIsSmallScreen(screenWidth < 785);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  const navigate = useNavigate()
  function toggleDropdown() {
    setIsOpen(!isOpen);
  }
  function checkAuth() {
    return !!Cookies.get('auth');
  }
  function deleteAuthCookie() {
    Cookies.remove('auth');
  }
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  function logOut() {
    deleteAuthCookie()
    window.localStorage.removeItem('dashboardData');

    window.location.href = "/"
  }


  useEffect(() => {
    if (checkAuth()) {
      setLogin(true)
    } else {
      setLogin(false)
    }
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const toggleMenu = () => {
    setMenu(!menu);
  }

  return (
    <div className={`navbar-wrapper ${menu ? 'active-menu' : ''}`}>
    {isSmallScreen &&
    <>
      {!menu &&
        <div className="logo-left">
          <NavLink
                exact
                activeClassName="image image-active"
                className="navlinkimage"
                to="/"
              >
            <img src={logo} alt="" className="logo-left-image" />
          </NavLink>
        </div>
      }
      
      <div className="navbar hamburger" onClick={toggleMenu}>
        {menu == false &&
          <MenuIcon className='hamburger-icon' />
        }
        {menu == true &&
          <CloseIcon className='hamburger-icon' />
        }
      </div>
    </>
    }
      {!isSmallScreen || menu &&
        <>
          <div className="navbar navbar-left">
            <div className="company-name" onClick={() => navigate(`/`)}>
              <div className="first">
                Camera
              </div>
              <div className="second">
                Cove
              </div>
            </div>
          </div>
          <div className="navbar navbar-centered">
            <div className="links">
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/"
              >
                Home
              </NavLink>
              <div className="dropdown" ref={dropdownRef} >
                <div to="" className={`dropdown-toggle ${isLocated ? 'active' : ''}`} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={toggleDropdown}>
                  Products <ArrowDropDownIcon onClick={toggleDropdown} className='dropdown-arrow' />
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ display: isOpen ? 'block' : 'none' }}>
                  <NavLink to="/products" className="dropdown-item" activeClassName="navlink active">All</NavLink>
                  <NavLink to="/cameras" className="dropdown-item" activeClassName="navlink active">Cameras</NavLink>
                  <NavLink to="/lenses" className="dropdown-item" activeClassName="navlink active">Lenses</NavLink>
                  <NavLink to="/accessories" className="dropdown-item" activeClassName="navlink active">Accessories</NavLink>
                  <NavLink to="/deals" className="dropdown-item" activeClassName="navlink active">Deals</NavLink>
                </div>
              </div>
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/products/us"
              >
                Our Products
              </NavLink>
              {/*
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/suggestions"
              >
                Suggestions
              </NavLink>
              */}
              

              
            </div>
          </div>
          <div className="navbar navbar-right">
            {login === false &&
              <>
                <button className="btn gradient-bg" onClick={() => navigate(`/login`)}>Log In</button>
                <button className="btn register gradient-bg-blue" onClick={() => navigate(`/register`)}>Register</button>
              </>
            }
            {login === true &&
              <>
                <div className="signed-in">
                  <div className="icons">
                    
                  </div>
                  <div className="icons">
                    <NavLink exact activeClassName="active-icon" className="account-icon" to="/conversations">
                      <NotificationsIcon className="heart-icon" fontSize="large" onClick={() => navigate(`/conversations`)} />
                      {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>}
                    </NavLink>

                    <NavLink
                      exact
                      activeClassName="active-icon"
                      className="account-icon"
                      to="/likes"
                    >
                      <FavoriteIcon className='heart-icon' fontSize="large" onClick={() => navigate(`/dashboard`)} />
                    </NavLink>
                    <NavLink
                      exact
                      activeClassName="active-icon"
                      className="account-icon"
                      to="/dashboard"
                    >
                      <AccountCircleIcon className='account-icon' fontSize="large" onClick={() => navigate(`/dashboard`)} />
                    </NavLink>
                  </div>
                  <button className="btn gradient-bg" onClick={logOut}>Log Out</button>
                </div>

              </>
            }

          </div>
        </>
      }
      {!isSmallScreen &&
        <>
          <div className="navbar navbar-left">
            <div className="company-name" onClick={() => navigate(`/`)}>
              <div className="first">
                Camera
              </div>
              <div className="second">
                Cove
              </div>
            </div>
          </div>
          <div className="navbar navbar-centered">
            <div className="links">
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/"
              >
                Home
              </NavLink>
              <div className="dropdown" ref={dropdownRef} >
                <div to="" className={`dropdown-toggle ${isLocated ? 'active' : ''}`} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={toggleDropdown}>
                  Products <ArrowDropDownIcon onClick={toggleDropdown} className='dropdown-arrow' />
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ display: isOpen ? 'block' : 'none' }}>
                  <NavLink to="/products" className="dropdown-item" activeClassName="navlink active">All</NavLink>
                  <NavLink to="/cameras" className="dropdown-item" activeClassName="navlink active">Cameras</NavLink>
                  <NavLink to="/lenses" className="dropdown-item" activeClassName="navlink active">Lenses</NavLink>
                  <NavLink to="/accessories" className="dropdown-item" activeClassName="navlink active">Accessories</NavLink>
                  <NavLink to="/deals" className="dropdown-item" activeClassName="navlink active">Deals</NavLink>
                </div>
              </div>
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/products/us"
              >
                Our Products
              </NavLink>
              {/*
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/suggestions"
              >
                Suggestions
              </NavLink>
              */}
              {/*
              <NavLink
                exact
                activeClassName="navlink active"
                className="navlink"
                to="/gallery"
              >
                Gallery
              </NavLink>
              */}
              
            </div>
          </div>
          <div className="navbar navbar-right">
            {login === false &&
              <>
                <button className="btn gradient-bg" onClick={() => navigate(`/login`)}>Log In</button>
                <button className="btn register gradient-bg-blue" onClick={() => navigate(`/register`)}>Register</button>
              </>
            }
            {login === true &&
              <>
                <div className="signed-in">
                  <div className="icons">
                    <NavLink exact activeClassName="active-icon" className="account-icon" to="/conversations">
                      <NotificationsIcon className="heart-icon" fontSize="large" onClick={() => navigate(`/conversations`)} />
                      {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>}
                    </NavLink>
                    <NavLink
                      exact
                      activeClassName="active-icon"
                      className="account-icon"
                      to="/likes"
                    >
                      <FavoriteIcon className='heart-icon' fontSize="large" onClick={() => navigate(`/dashboard`)} />
                    </NavLink>
                    <NavLink
                      exact
                      activeClassName="active-icon"
                      className="account-icon"
                      to="/dashboard"
                    >
                      <AccountCircleIcon className='account-icon' fontSize="large" onClick={() => navigate(`/dashboard`)} />
                    </NavLink>
                  </div>
                  <button className="btn gradient-bg" onClick={logOut}>Log Out</button>
                </div>

              </>
            }

          </div>
        </>
      }

    </div>
  )
}

export default Navbar