import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Write from '../components/write/Write.jsx'

const BlogWrite = () => {
  return (
    <div className='login-wrapper'>
        <Navbar />
        <section className="header-section dashboard">
          <Write />
        </section>
        <Footer />
    </div>
  )
}

export default BlogWrite