import React from 'react'
import Footer from '../components/footer/Footer'
import Gallery from '../components/gallery/Gallery'
import Navbar from '../components/navbar/Navbar'
import '../index.scss'

const Home = () => {
  document.title = "Gallery";
  return (
    <div className='gallery page'>
        <Navbar />
        <section className="gallery section">
          <Gallery />
        </section>
        <Footer />
    </div>
  )
}

export default Home