import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Dashboard from '../components/dashboard/Dashboard'

const SignIn = () => {
  return (
    <div className='login-wrapper'>
        <Navbar />
        <section className="header-section dashboard">
          <Dashboard />
        </section>
        <Footer />
    </div>
  )
}

export default SignIn