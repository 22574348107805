import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import Card from '../Products/Card/Card'
import Loader from '../loader/Loader.jsx'
import { NavLink, useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Tooltip from '@mui/material/Tooltip';
import './dashboard.scss'
const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [reset, setReset] = useState(false)
    const [likes, setLikes] = useState([])
    const [verification, setVerification] = useState(false)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState('');
    const [date, setDate] = useState('');
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [permission, setPermission] = useState(false);
    const [seller, setSeller] = useState(false);
    const [premium, setPremium] = useState(false);
    const [sellerId, setSellerId] = useState('');
    const navigate = useNavigate()
    document.title = "Dashboard";

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseToast = () => {
        setToast(false);
    };

    const updateUserDetails = async (event) => {
        event.preventDefault();
        try {
          const response = await fetch('https://api.cameracove.com/update-user', {
            method: 'PUT',
            headers: {
                Authorization: `${Cookies.get('auth')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email })
          });
          const data = await response.json();
          if (response.status != 200) {
            setError('An error occurred. Please try again.');
            return;
         }
         setUpdated(true)
         setToast(true)
        } catch (error) {
          setError(error.message);
        }
      };

    const deleteUser = async (event) => {
        event.preventDefault();
        const response = await fetch('https://api.cameracove.com/delete-user', {
            method: 'DELETE',
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            }
        });
        if (response.status !== 200) {
            setError('An error occurred. Please try again.');
            return;
        }
        const data = await response.json();
        window.location.href = "/login"

    };
    const likedItemRefs = useRef([]);


    const resetPassword = async (event) => {
        event.preventDefault();
        const response = await fetch('https://api.cameracove.com/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        if (response.status !== 200) {
            setError('An error occurred. Please try again.');
            return;
        }
        const data = await response.json();
        setReset(true)
    };

    function cacheFunction() {
        setLoading(true);
        const cachedData = localStorage.getItem('dashboardData');
        if (cachedData) {
          // If there is cached data, set the state variables using the cached data
          const data = JSON.parse(cachedData);
          setName(data.name);
          setEmail(data.email);
          setImage(data.profilePicture);
          setVerification(data.hasVerificationRequest)
          setPermission(data.isApproved)
          let date = new Date(date.date);
          let parsedDate = date.toLocaleDateString();
          setDate(parsedDate);
          setLoading(false);
        } else {
          // If there is no cached data, make a GET request to the /dashboard endpoint
          fetch('https://api.cameracove.com/dashboard', {
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            }
          })
            .then(response => {
              return response.json();
            })
            .then(data => {
              setName(data.name);
              setEmail(data.email);
              setImage(data.profilePicture);
              setVerification(data.hasVerificationRequest);
              setPermission(data.isApproved);
              let date = new Date(data.date);
              let parsedDate = date.toLocaleDateString();
              setDate(parsedDate)
              setLoading(false);
              // Cache the data in localStorage
              localStorage.setItem('dashboardData', JSON.stringify(data));
            })
            .catch(error => {
              Cookies.remove('auth');
              window.location.href = '/login';
              setLoading(false);
            });
        }
    }

    const generateLink = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://api.cameracove.com/api/billing-portal', {
                method: 'GET',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            const data = await response.json();
            //redirect to link
            window.location.href=data.url;
        } catch (error) {
            setError(error.message);
        }
    }


    function hardCheck() {
        setLoading(true);
        fetch('https://api.cameracove.com/dashboard', {
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            },
          })
            .then(response => {
              return response.json();
            })
            .then(data => {
              setName(data.name);
              setEmail(data.email);
              setImage(data.profilePicture);
              setVerification(data.hasVerificationRequest)
              setPermission(data.isApproved)
              setPremium(data.isPremium)
              setLoading(false);
              // Cache the data in localStorage
              localStorage.setItem('dashboardData', JSON.stringify(data));
            })
            .catch(error => {
              Cookies.remove('auth');
              window.location.href = '/login';
              setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        fetch('https://api.cameracove.com/dashboard', {
            method: 'GET',
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            }
        })
            .then(response => {
                if (response.status != 200) {
                    window.location.href = '/login';
                    setLoading(false);
                }
              return response.json();
            })
            .then(data => {
              setName(data.name);
              setEmail(data.email);
              setImage(data.profilePicture);
              setVerification(data.hasVerificationRequest)
              setPermission(data.isApproved);
              setSeller(data.isSeller);
              setPremium(data.isPremium);
              setSellerId(data.sellerId);
              let date = new Date(data.date);
              let parsedDate = date.toLocaleDateString();
              setDate(parsedDate)
              setLoading(false);
            })
            .catch(error => {
                //alert(error);
              window.location.href = '/login';
              setLoading(false);
            });
            async function fetchData() {
                const response = await fetch('https://api.cameracove.com/likes', {
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                    }
                });
                const data = await response.json();
                setLikes(Object.values(data));
              }
            fetchData();
      }, []);
      
    return (
        <div className='dashboard-wrapper'>
            <div className="dashboard">
                {loading === true &&
                    <Loader />
                }
                {loading === false &&
                    <>
                        <div className="top-wrapper">
                            <div className="profile-image">
                                <img src={image} alt="profile picture" className="profile-picture" />
                            </div>
                            <div className="welcome-user">
                                <div className="name">
                                    Welcome, <b>{name}</b>!
                                </div>
                                <div className="date-registered">
                                   Registered: {date}
                                </div>
                            </div>
                        </div>
                        
                        <div className="profile">
                            <div className="profile-information">
                                <span className="profile-user-header">Profile</span>
                                <form onSubmit={updateUserDetails}>
                                    <div className="profile-details">
                                        <div className="name form-field">
                                            <span className="field-text">Name</span>
                                            <input type="text" className='field-value changeable-input' value={name} onChange={(event) => setName(event.target.value)}/>
                                        </div>                                    
                                        <br></br>
                                        <div className="email form-field">
                                            <span className="field-text">Email</span>
                                            <input type="email" className='field-value changeable-input' value={email} onChange={(event) => setEmail(event.target.value)}/>
                                        </div>   
                                            <input type="submit" className='btn-large gradient-bg save-changes' value="Save Changes" />
                                        {updated == true &&
                                            <Snackbar open={toast} autoHideDuration={6000} onClose={handleCloseToast}>
                                                <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%' }}>
                                                Profile details have been updated!
                                                </Alert>
                                            </Snackbar>
                                        }
                                    </div>
                                </form>
                                {error && <p className="error">{error}</p>}
                            </div>

                            <div className="dashboard-profile-actions">
                                <span className="profile-user-header">Actions</span>
                                <div className="arranged-dashboard">
                                    {reset === false && <button className="btn-large gradient-bg-blue dash-btn" onClick={() => navigate('/reset')}> Reset Password</button>}
                                    {reset === true && <button className="btn-large gradient-bg-blue success dash-btn"> Request Sent <CheckCircleIcon className='success-icon-interior' /></button>}
                                    <button className="btn-large gradient-bg-blue dash-btn" onClick={handleClickOpen}>Delete Account</button>
                                    {verification == false &&
                                        <button className="btn-large gradient-bg-blue dash-btn" onClick={() => navigate(`/verification`)}>Become a Writer</button>
                                    }
                                    {verification == true && permission == false &&
                                        <button className="btn-disabled dash-btn" type="button" onClick={hardCheck}>Check Verification</button>
                                    }
                                    {permission == true && 
                                        <button className="btn-large gradient-bg-blue dash-btn" onClick={() => navigate(`/write`)}>Writer Dashboard</button>
                                    }
                                    <button className="btn-large gradient-bg ai-button dash-btn" onClick={() => navigate('/ai')}>AI Testing <PsychologyIcon /></button>
                                    {premium === "pending" && 
                                    <>
                                        <div className="pending-wrapper">
                                            <Tooltip title="You have been approved, please subscribe to start selling!">
                                                <button className="btn-disabled dash-btn" type="button" onClick={() => navigate('/subscribe')}>Subscribe</button>
                                            </Tooltip>
                                            <span className='subtitle'>You have been approved, please subscribe to start selling!</span>
                                        </div>
                                       
                                    </>
                                    }
                                    {seller == true && sellerId != null && premium &&
                                        <button className="btn-large gradient-bg-blue dash-btn" onClick={() => navigate(`/seller/dashboard/${sellerId}`)}>Seller Dashboard</button>
                                    }
                                    {!premium &&
                                        <button className="btn-large gradient-bg-blue dash-btn" onClick={() => navigate(`/seller-signup`)}>Seller Signup</button>
                                    }
                                    {premium === true && 
                                        <button className="btn-large gradient-bg-blue dash-btn" onClick={() => generateLink()}>Change Subscription</button>
                                    }
                                </div>
                            </div>
                            {error != '' &&
                                <Alert severity="error" open={true}>{error}</Alert>
                            }
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Are you sure you want to delete your account?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Your account and data will be permanently deleted. Are you sure you want to do this? 
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Disagree</Button>
                                    <Button onClick={deleteUser} autoFocus>
                                        Agree
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        
                        
                    </>
                }
            </div>

        </div>
    )
}

export default Dashboard