import React, { useState } from 'react';
import './quote.scss';
import Cookies from 'js-cookie';

const QuoteModal = ({ onClose, conversationId }) => {
  const [itemPrice, setItemPrice] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create the quote data object
    const quoteData = {
      itemPrice,
      shippingPrice,
      status: 'Pending',
    };

    // Call the handleQuoteSubmit function from the parent component
    handleQuoteSubmit(quoteData);
    
  };

  const handleQuoteSubmit = async (quoteData) => {
    try {
      // Send the quote message
      const response = await fetch('https://api.cameracove.com/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${Cookies.get('auth')}`,
        },
        body: JSON.stringify({
          message: quoteData,
          conversationId: conversationId,
          type: 'quote',
        }),
      });
      const data = await response.json();
      
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2 className="modal-title">Submit a Quote</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-group">
            <label htmlFor="itemPrice" className="form-label">
              Item Price
            </label>
            <input
              type="text"
              id="itemPrice"
              className="form-input"
              value={itemPrice}
              onChange={(e) => setItemPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="shippingPrice" className="form-label">
              Shipping Price
            </label>
            <input
              type="text"
              id="shippingPrice"
              className="form-input"
              value={shippingPrice}
              onChange={(e) => setShippingPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit-button-quote">
              Submit
            </button>
            
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuoteModal;
