import React from 'react'
import { useEffect, useState } from 'react';
import Loader from '../loader/Loader.jsx'
import Cookies from 'js-cookie';
import './post.scss'

const Post = () => {
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState({});
    const postId = window.location.pathname.split('/')[2];  // Get the post ID from the URL

    useEffect(() => {
        async function fetchPost() {
            setLoading(true)
            try {
                const response = await fetch(`https://api.cameracove.com/article/${postId}`);
                const data = await response.json();
                setPost(data.post);
                setLoading(false)
            } catch (error) {
                console.error(error);
                setLoading(false)
            }
        }
        fetchPost();
    }, []);
    return (
        <div className="post">
            {loading == true &&
                <Loader />
            }
            {loading == false &&
                <>
                    <div className="pagination">
                        <a href="/suggestions" className='back-url'>Back to articles</a>
                    </div>
                    <div className='post-wrapper'>
                        <div className="post-header">
                            <div className="post-title">
                                {post.title}
                            </div>
                            <div className="user-data">
                                <div className="profile-picture">
                                    <img src={post.profilePicture} alt="profile-picture" className='profile-picture-image' />
                                </div>
                                <div className="article-information">
                                    <div className="name">{post.username}</div>
                                    <div className="date">{post.date}</div>
                                </div>
                                
                            </div>

                        </div>
                        <div className="side-image">
                            <img src={post.updatedImage} alt="side image" className='side-image image' />
                        </div>
                    </div>
                    <div className="post-text">
                        <pre className='no-format'>
                            {post.message}
                        </pre>
                    </div>
                </>
            }
            
        </div>

    )
}

export default Post