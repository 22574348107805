import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import './edit.scss'

const ImageEdit = () => {
    const [post, setPost] = useState([]);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [body, setBody] = useState('');
    const [lens, setLens] = useState('');
    const [fstop, setFstop] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [validating, setValidating] = useState(false);
    const [success, setSuccess] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const postId = window.location.pathname.split('/')[2];  // Get the post ID from the URL
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        async function fetchPost() {
            setLoading(true)
            try {
                const response = await fetch(`https://api.cameracove.com/image/${postId}`);
                const data = await response.json();
                setPost(data.post);
                setCountry(data.post.country)
                setCity(data.post.city)
                setBody(data.post.body)
                setLens(data.post.lens)
                setFstop(data.post.fstop)
                setPreview(data.post.updatedImage)
                setLoading(false)
            } catch (error) {
                console.error(error);
                setLoading(false)
            }
        }
        fetchPost();
    }, []);

    const handleChange = (event) => {
        // Get the file from the input
        const newFile = event.target.files[0];

        // Set the file in the state
        setFile(newFile);

        // Create a preview
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(newFile);
    }

    const deletePost = async (event) => {
        try {
            const response = await fetch(`https://api.cameracove.com/delete-image/${postId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            if (response.status == 200) {
                const data = await response.json();
                setDeleted(true)
                setValidating(false)
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false)
                setDeleted(false)
            }
        } catch (error) {
            setError(error.error);
            setValidating(false)
        }
    }

    const submitBlog = async (event) => {
        event.preventDefault();
        setValidating(true)
        const formData = new FormData();
        formData.append('city', city);
        formData.append('country', country);
        formData.append('body', body);
        formData.append('lens', lens);
        formData.append('fstop', fstop);
        formData.append('file', file);
        try {
            const response = await fetch(`https://api.cameracove.com/edit-image/${postId}`, {
                method: 'PUT',
                body: formData,
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });

            if (response.status == 200) {
                const data = await response.json();
                setSuccess(true)
                setValidating(false)
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false)
                setSuccess(true)
            }
        } catch (error) {
            setError(error.error);
            setValidating(false)
        }
    }
    return (
        <div className='edit-wrapper-post'>
            <div className="write-new-wrapper">
                {success == false &&
                <>
                    <form onSubmit={submitBlog} className='edit-form-main'>
                        <div className="file">
                            {preview &&
                                <div className="image-preview">
                                    <img src={preview} alt="Preview" className='preview-image' />
                                </div>
                            }
                            <span className="field-type">Header Image</span>
                            <input type="file" onChange={handleChange} className='file-upload' />
                        </div>
                        <div className="content">
                            <span className="field-type">Image City</span>
                            <input type="text" name="title" placeholder='Enter city' value={post.city} className='text-input' value={city} onChange={(event) => setCity(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Article Country</span>
                            <input type="text" name="title" placeholder='Enter country' value={post.country} className='text-input' value={country} onChange={(event) => setCountry(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Camera Body</span>
                            <input type="text" name="body" placeholder='Sony a7RIII' value={post.body} className='text-input write-input' value={body} onChange={(event) => setBody(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Lens</span>
                            <input type="text" name="lens" placeholder='Sony 70-200 f4' value={post.lens} className='text-input write-input' value={lens} onChange={(event) => setLens(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">F-Stop</span>
                            <input type="text" name="lens" placeholder='f7.1' value={post.fstop} className='text-input write-input' value={fstop} onChange={(event) => setFstop(event.target.value)} />
                        </div>
                        {validating === false &&
                            <input type="submit" className="btn-large gradient-bg submit-button" placeholder='Submit Article' onClick={submitBlog}/>
                        }
                        {validating === true &&
                            <button className="btn-large gradient-bg submit-button"><CircularProgress color="inherit" className='validating' /></button>
                        }
                        
                    </form>
                    <div className="delete-post">
                        {validating === false &&
                            <button className="btn-large gradient-bg-blue delete-button" onClick={deletePost}>Delete Post</button>
                        }
                        {validating === true &&
                            <button className="btn-large gradient-bg-blue delete-button"><CircularProgress color="inherit" className='validating' /></button>
                        }
                    </div>
                    
                </>
                }

            </div>
            {success == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your image has been updated.
                    </div>
                    <div className="buttons">
                        <button className="btn-large gradient-bg" onClick={() => setSuccess(false)}>Write Another</button>
                    </div>
                </div>
            }
            {deleted == true &&
                <div className="success">
                    <br></br>
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your image has been deleted.
                    </div>
                    <button className="btn-large gradient-bg" onClick={() => navigate(`/upload`)}>Back to Images</button>
                </div>
            }
        </div>
    )
}

export default ImageEdit