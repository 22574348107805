import React, { useEffect } from 'react';
import Loader from '../loader/Loader';
import Cookies from 'js-cookie';


const GoogleCallback = () => {
    document.title = "Logging you in..."
    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get("token");
        if (!token) return;
        fetch('https://api.cameracove.com/auth/google/authenticate', {
          method: 'POST',
          body: JSON.stringify({ token }),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => response.json())
          .then((data) => {
            Cookies.set('auth', data.sessionCookie);
          }).then(() =>
            window.location.href = "/dashboard"
          );
      }, []);

    return <Loader />;
};

export default GoogleCallback;
