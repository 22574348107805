import {useEffect, useState} from 'react'
import './gallery.scss'
import Image from './image/Image'
const Gallery = () => {
  return (
    <div className='gallery-wrapper'>
        <div className="gallery-contents">
            <div className="gallery-title">
                Image Gallery
            </div>
            <div className="gallery-images">
                <Image />
            </div>
        </div>
    </div>
  )
}

export default Gallery