import React from 'react'
import Footer from '../components/footer/Footer'
import Reset from '../components/Login/Reset'
import Navbar from '../components/navbar/Navbar'
import '../index.scss'
const ResetPage = () => {
  return (
    <div className='product-page page'>
        <Navbar />
        <section className="header-section recommendations">
          <Reset />
        </section>
        <Footer />
    </div>
  )
}

export default ResetPage