import {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import './writer.scss'

const Writer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [validating, setValidating] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setValidating(true)
        fetch('https://api.cameracove.com/verification-request', {
          method: 'POST',
          headers: {
              Authorization: `${Cookies.get('auth')}`,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            message: message
          })
        })
        .then(response => {
            if (response.status === 200) {
                setSuccess(true)
                setValidating(false)
            } else {
                setError("Error sending data.")
                setSuccess(false)
                setValidating(false)
            }
          })
          .catch(error => {
            setError(error.erorr)
            setSuccess(false)
            setValidating(false)
          });
      };
  return (
    <div className='writer-register-wrapper'>
        <div className="verification-header">
            <div className="login-header">
                Write For Us!
            </div>
            <div className="login-subheader">
               Fill out this form to write blogs for us!
            </div>  
        </div>
        <div className="validation-form">
            {success == false &&
                <form onSubmit={handleSubmit}>
                    <div className="email">
                        <span className="field-type">Email</span>
                        <input type="email" name="email" placeholder='Enter email' className='text-input' value={email} onChange={(event) => setEmail(event.target.value)} />
                    </div>
                    <div className="message">
                        <span className="field-type">Message</span>
                        <textarea type="text" name="message" placeholder='Why should you write for us?' className='text-area' value={message} onChange={(event) => setMessage(event.target.value)} />
                    </div>
                    {error != null &&
                        <div className="error">
                            <div className="error-text">{error}</div>
                        </div>
                    }
                    {validating == false &&
                        <button className='btn-large gradient-bg submit-verification'>Request Verification</button>
                    }
                    {validating == true &&
                        <button className='btn-large gradient-bg submit-verification'><CircularProgress color="inherit" className='validating' /></button>
                    }
                </form>
            }
            {success == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon'/>
                    <div className="success-message">
                        Your message has been received. Please check your inbox as we will be in touch.
                    </div>
                </div>
                
            }
        </div>
    </div>
  )
}

export default Writer