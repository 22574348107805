import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './content.scss'

const Content = (props) => {
    const [subscribed, setSubscribed] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const views = Object.values(props.viewed)

    const navigate = useNavigate()
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const response = await fetch('https://api.cameracove.com/newsletter', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
          });
    
          if (response.status == 200) {
            const data = await response.json();
            setSubscribed(true)
          } else {
            const error = await response.json();
            setError(error.error);
          }
        } catch (error) {
            setError(error.error);
        }
      };
  return (
    <div className='right-content'>
        <div className="newsletter-signup">
            <div className="section-header-right">Subscribe to Our Newsletter</div>
            {subscribed == false &&
                <form onSubmit={handleSubmit}>
                    <div className="form-contents">
                        <input type="email" name='email' className='text-input' placeholder='Email' onChange={e => setEmail(e.target.value)} />
                        <input type="submit" className="btn-large gradient-bg" value="Sign Up" />
                    </div>
                </form>
            }
            {subscribed == true &&
                <div className="thanks">
                    Success! Thank you for subscribing!
                </div>
            } 
            {error != null &&
                <div className="error">
                    {error}
                </div>
            } 
        </div>
        <div className="most-popular">
            <div className="section-header-right">Most Popular Posts</div>
            {views.map(post => (
                <div className="content-wrapper" key={post.id} onClick={() => navigate(`/article/${post.id}`)}>
                    <div className="content-image">
                        <LazyLoadImage
                            alt="Post image"
                            src={post.updatedImage} // use normal <img> attributes as props
                            className='left-image'
                        />
                    </div>
                    <div className="content-text">
                        <div className="post-author">{post.username}</div>
                        <div className="post-title">{post.title}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Content