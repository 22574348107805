import React, { useState } from 'react';
import image from '../../assets/register.jpg'
import GoogleIcon from '@mui/icons-material/Google';
import './login.scss'
import { CircularProgress } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Cookies from 'js-cookie';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import avatar_empty from '../../assets/avatar_empty.png'

const Register = () => {
    const provider = new GoogleAuthProvider();
    const firebaseConfig = {
        apiKey: "AIzaSyBzUasS-CkrwTli9S2Wq5cGXdl-IGOk_G4",
        authDomain: "photography-store-70326.firebaseapp.com",
        databaseURL: "https://photography-store-70326-default-rtdb.firebaseio.com/",
        projectId: "photography-store-70326",
        storageBucket: "photography-store-70326.appspot.com",
        messagingSenderId: "907110427993",
        appId: "1:907110427993:web:ad2a1ad680cccc4210b048",
        measurementId: "G-BSRXJL5SBX"
    };
    const app = firebase.initializeApp(firebaseConfig);

    document.title = "Register"
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [validating, setValidating] = useState(false);
    const CLIENT_ID = "964015494828-2m2v0crrhbnn1c3kt1no5f5gre665fht.apps.googleusercontent.com"
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [inputType, setInputType] = useState('password');

    function toggleInputType() {
        if (inputType === 'password') {
          setInputType('text');
        } else {
          setInputType('password');
        }
      }
      
    const handleClick = async () => {
        const auth = getAuth(app);
        try {
          const res = await signInWithPopup(auth, provider);
          const user = res.user;
          user.getIdToken().then(function (idToken) {
            window.location.href = `/auth/google?token=${idToken}`
          }).catch(error => {
            console.log(error);
          });
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
    };
    
    
    
      

    const handleChange = (event) => {
        // Get the file from the input
        const newFile = event.target.files[0];

        // Set the file in the state
        setFile(newFile);

        // Create a preview
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(newFile);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (fname == "" || lname == "" || email == "" || password == "" || file == null) {
            setError("All fields must be filled")
        }
        else if (password.length < 8) {
            setError("Password must be at least 8 characters")
        }
        else if (file.size > 3000000) {
            setError("File size must be less than 3MB")
        }
        else if (file != null) {
            setValidating(true)
            const formData = new FormData();
            formData.append('fname', fname);
            formData.append('lname', lname);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('file', file);
            try {
                const response = await fetch('https://api.cameracove.com/register', {
                    method: 'POST',
                    body: formData,
                });

                if (response.status == 200) {
                    const data = await response.json();
                    Cookies.set('auth', data.sessionCookie);
                    window.location.href = "/dashboard"
                } else {
                    const error = await response.json();
                    setError(error.error.message);
                    setValidating(false)
                }
            } catch (error) {
                setError("Error. Please try again later.");
                setValidating(false)
            }
        }else {
            setError("Error. Please upload a profile picture.")
        }

    };
    return (
        <div className='login-wrapper'>
            <div className="login">
                <div className="login-left">
                    <div className="login-header">
                        Register here!
                    </div>
                    <div className="login-subheader">
                        We are glad you are here! Please fill out the fields.
                    </div>
                    <div className="login-form register-form">
                        <form onSubmit={handleSubmit}>
                            <div className="file">
                                {!preview &&
                                    <div className="image-preview">
                                        <img src={avatar_empty} alt="Preview" className='preview-image' />
                                    </div>
                                }
                                {preview &&
                                    <div className="image-preview">
                                        <img src={preview} alt="Preview" className='preview-image' />
                                    </div>
                                }
                                <span className="field-type">Profile Image</span>
                                <input type="file" onChange={handleChange} className='file-upload' />
                            </div>
                            <div className="f-name">
                                <span className="field-type">First Name</span>
                                <input type="text" name="first-name" placeholder='John' className='login-text-input' value={fname} onChange={(event) => setFname(event.target.value)} />
                            </div>
                            <div className="l-name">
                                <span className="field-type">Last Name</span>
                                <input type="text" name="last-name" placeholder='Doe' className='login-text-input' value={lname} onChange={(event) => setLname(event.target.value)} />
                            </div>
                            <div className="email">
                                <span className="field-type">Email</span>
                                <input type="text" name="email" placeholder='Enter email' className='login-text-input' value={email} onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            <div className="password">
                                <span className="field-type">Password</span>
                                <div className="view-password">
                                    <input type={inputType} placeholder='Enter password' className='login-password-input' value={password} onChange={(event) => setPassword(event.target.value)}/><RemoveRedEyeIcon className='view-icon' onClick={toggleInputType}/>
                                </div>                            
                            </div>
                            {error != null &&
                                <div className="error-text">
                                    {error}
                                </div>
                            }
                            {validating === false &&
                                <input type="submit" value="Sign Up" className='btn-large sign-submit gradient-bg' />
                            }
                            {validating === true &&
                                <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating' /></button>
                            }
                        </form>
                        {/*
                            <div className="google-authentication">
                                <button className="btn-large gradient-bg-blue google-auth" onClick={handleClick}><GoogleIcon /> Sign Up With Google</button>
                            </div>
                        */}
                        
                    </div>

                    <div className="register">
                        <span className="new-user">Already have an account?</span> <a href="/login" className='sign-up-text'>Log In</a>
                    </div>
                </div>
                <div className="login-right">
                    <LazyLoadImage src={image} alt="Register" className="login-image" />
                </div>
            </div>
        </div>
    )
}

export default Register