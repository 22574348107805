import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Post from '../components/post/Post.jsx'

const ArticlePost = () => {
  return (
    <div className='login-wrapper'>
        <Navbar />
        <section className="header-section dashboard">
          <Post />
        </section>
        <Footer />
    </div>
  )
}

export default ArticlePost