import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Error from '../components/error/Error.jsx'

const ErrorPage = () => {
  return (
    <div className='error-component-wrapper'>
        <Navbar />
        <section className="header-section edit">
          <Error />
        </section>
        <Footer />
    </div>
  )
}

export default ErrorPage