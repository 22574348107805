import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Chat from '../components/chat/Chat'

const SellerSell = () => {
  return (
    <div className='chat-page page'>
        <Navbar />
        <section className="header-section login">
            <Chat />
        </section>
        <Footer />
    </div>
  )
}

export default SellerSell