import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Search from '../search/Search'
import Slider from '@mui/material/Slider';
import './products.scss'
import Card from './Card/Card'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Loader from '../loader/Loader';
import Connection from '../error/Connection';
import Results from '../error/Results';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { set } from 'date-fns';

const ProductAll = (props) => {
    const endpoint = props.endpoint
    const title = props.products
    const LazyCard = lazy(() => import('./Card/Card'))
    document.title = title + " Products";
    const [brand, setBrand] = useState('');
    const [store, setStore] = useState('');
    const [sort, setSort] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [likes, setLikes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [heart, setHeart] = useState(true);
    const [error, setError] = useState(false);
    const [price, setPrice] = useState(false);
    const [filter, setFilter] = useState(false);
    const [minPrice, setMinPrice] = useState(50);
    const [maxPrice, setMaxPrice] = useState(8000);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < 480);
    const [previousPage, setPreviousPage] = useState(false);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
            setIsSmallScreen(screenWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    useEffect(() => {
        const handleUnload = () => {
            sessionStorage.removeItem('scrollPosition');
        };
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);


    useEffect(() => {
        setLoading(true);
        Promise.all([fetchData(), fetchLikes()]).then(([dataResponse, likesResponse]) => {
            // Check if both API calls have returned a status
            if (dataResponse.status && likesResponse.status) {
                if (likesResponse.status == 200 && likes.length != 0) {
                    setLoading(false);
                }
                else if (likesResponse.status != 200 && likes.length == 0) {
                    setLoading(false)
                }
                // Set the loading state to false if both API calls have returned a status
            }
        }).catch((error) => {
            console.error(error);
        });
        async function fetchData() {
            try {
                let response;
                const urlParams = new URLSearchParams(window.location.search);
                const limit = urlParams.get('limit') || 100;
                const offset = urlParams.get('offset') || 0;
                if (endpoint === "/products") {
                    response = await fetch(`https://api.cameracove.com/products?limit=${limit}&offset=${offset}`);
                    setShow(true);
                    if (offset >= 100) {
                        setPreviousPage(true)
                    }
                } 
                else if (endpoint === "/us") {
                    response = await fetch(`https://api.cameracove.com/products/us/all`);
                    setShow(false);
                }
                else {
                    response = await fetch(`https://api.cameracove.com/products${endpoint}`);
                    setShow(false);
                }
                const data = await response.json();
                if (endpoint === "/deals") {
                    setProducts(data.products);
                }
                else if (endpoint === "/us") {
                    setProducts([data[0]]);
                }
                else if (endpoint === "/all") {
                    setProducts(data[0]);
                }
                else {
                    setProducts(data);
                }
                const savedPosition = await sessionStorage.getItem('scrollPosition');
                const categorySaved = await sessionStorage.getItem('category');
                if (savedPosition && categorySaved == endpoint) {
                    const position = parseInt(savedPosition, 10);
                    setTimeout(() => {
                        window.scrollTo(0, position);
                    }, 0);
                }
                setLoading(false);
                //setFilters();
                return response
            } catch (error) {
                setError(true);
                setLoading(false)
            }
        }
        async function fetchLikes() {
            try {
                const response = await fetch(`https://api.cameracove.com/likes`, {
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                    }
                }
                );
                try {
                    if (response.headers.get('content-type').includes('application/json')) {
                        setHeart(true)
                        const likes = await response.json();
                        await setLikes(likes);
                    } else {
                        setHeart(false)
                    }
                    return response;
                }catch(error){
                    if (response.ok) {
                        setHeart(true)
                    }else {
                        setHeart(false)
                    }
                    console.log(error)
                }
            } catch (error) {
                console.log(error)
                setError(true)
            }
        }
    }, [endpoint]);

    const marks = [
        {
            value: 50,
            label: '$50',
        },
        {
            value: 1500,
            label: '$1500',
        },
        {
            value: 3000,
            label: '$3000',
        },
        {
            value: 4500,
            label: '$4500',
        },
        {
            value: 6000,
            label: '$6000',
        },
        {
            value: 8000,
            label: '$8,000',
        },
    ];
    useEffect(() => {
        if (products.length > 0) {
            const savedMinPrice = sessionStorage.getItem('minPrice');
            const savedMaxPrice = sessionStorage.getItem('maxPrice');
            const savedBrand = sessionStorage.getItem('brand');
            const savedSort = sessionStorage.getItem('sort');
            const savedStore = sessionStorage.getItem('store');

            if (savedMinPrice && savedMaxPrice) {
                setMinPrice(parseInt(savedMinPrice));
                setMaxPrice(parseInt(savedMaxPrice));
                setPrice(true);
                setFilteredProducts(products.filter(product => product.price >= minPrice && product.price <= maxPrice));
            }

            if (savedBrand) {
                setBrand(savedBrand);
                setFilteredProducts(products.filter(product => product.brand === savedBrand));
                setFiltered(true);
                //setShow(false);
            }

            if (savedSort) {
                setSort(savedSort);
                if (savedSort === "high") {
                    sortByPriceHighToLow();
                } else {
                    sortByPriceLowToHigh();
                }
            }

            if (savedStore) {
                setStore(savedStore);
                setFilteredProducts(filteredProducts.filter(product => product.store === savedStore));
                setFiltered(true)
            }
        }
    }, [products])




    const handlePriceChange = (event, newValue) => {
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
        setPrice(true);
        sessionStorage.setItem('minPrice', newValue[0]);
        sessionStorage.setItem('maxPrice', newValue[1]);
        if (sort != '' || store != '' || brand != '') {
            setFilteredProducts(filteredProducts.filter(product => product.price >= minPrice && product.price <= maxPrice));
        }
        else {
            setFilteredProducts(products.filter(product => product.price >= minPrice && product.price <= maxPrice));
        }
        setFiltered(true);
    };

    const handleSearchResults = (results) => {
        setLoading(true)
        setSearchResults(results);
        setSearch(true);
        setShow(false);
    }
    const handleChange = (event) => {
        const selectedBrand = event.target.value;
        setBrand(selectedBrand);
        sessionStorage.setItem('brand', selectedBrand);
        if (selectedBrand) {
            if (sort != '' || store != '' || price == true) {
                setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand));
                setFiltered(true);
            } else {
                setFilteredProducts(products.filter(product => product.brand === selectedBrand));
                setFiltered(true);
            }

        }
        if (endpoint != "/products") {
            setShow(false);
        }
    };

    const handleSort = (event) => {
        const method = event.target.value;
        setSort(method);
        sessionStorage.setItem('sort', method);
        if (method == "high") {
            sortByPriceHighToLow();
        }
        if (method == "low") {
            sortByPriceLowToHigh();
        }
    };

    function sortByPriceLowToHigh() {
        if (brand != '' || store != '' || price == true) {
            const newFilter = filteredProducts.sort((a, b) => a.price - b.price)
            setFilteredProducts(newFilter);
            setFiltered(true);
            if (endpoint != "/products") {
                setShow(false);
            }
        }
        else {
            setFilteredProducts(products.sort((a, b) => a.price - b.price));
            setFiltered(true);
            if (endpoint != "/products") {
                setShow(false);
            }
        }
        sessionStorage.setItem("sort", "low");
    }

    function sortByPriceHighToLow() {
        if (brand != '' || store != '' || price == true) {
            const newFilter = filteredProducts.sort((a, b) => b.price - a.price)
            setFilteredProducts(newFilter);
        }
        else {
            setFilteredProducts(products.sort((a, b) => b.price - a.price));
        }
        setFiltered(true);
        if (endpoint != "/products") {
            setShow(false);
        }
        sessionStorage.setItem("sort", "high");
    }

    const handleStore = (event) => {
        const selectedStore = event.target.value;
        setStore(selectedStore);
        // Check if there are already filtered products
        if (sort != '' || brand != '' || price == true) {
            setFilteredProducts(filteredProducts.filter(product => product.store === selectedStore));
        } else {
            // If there aren't, filter the original list of products by store
            setFilteredProducts(products.filter(product => product.store === selectedStore));
            setFiltered(true);
        }
        if (endpoint != "/products") {
            setShow(false);
        }
        sessionStorage.setItem("store", selectedStore);
    };

    const resetData = () => {
        setFiltered(false);
        setSearch(false);
        setSearchResults([]);
        setBrand('');
        setStore('');
        setMinPrice(50);
        setMaxPrice(8000);
        setSort('');
        setFilteredProducts([]);
        sessionStorage.clear();
    }

    async function loadMore() {
        const urlParams = new URLSearchParams(window.location.search);
        const limit = urlParams.get('limit') || 100;
        const offset = urlParams.get('offset') || 0;
        setLoading(true)
        setOffset(prevOffset => prevOffset + 100);
        window.location.href = `/products?limit=${limit}&offset=${parseInt(offset) + 100}`
    }

    function loadLess() {
        const urlParams = new URLSearchParams(window.location.search);
        const limit = urlParams.get('limit') || 100;
        const offset = urlParams.get('offset') || 0;
        setLoading(true)
        setOffset(prevOffset => prevOffset - 100);
        window.location.href = `/products?limit=${limit}&offset=${parseInt(offset) - 100}`
    }



    return (
        <div className='products-wrapper-main'>
            {loading == false && error == false &&
                <>
                    <div className="products-header">
                        <span className="view">{title}</span> <span className="products">Products</span>
                    </div>
                    <div className="search-wrapper-products">
                        <div className="search">
                            <div className="search-item">
                                {title != "Deals" &&
                                    <Search value={false} placeholder={'Sony a7'} category={title} onSearchResults={handleSearchResults} />
                                }
                                {title === "Deals" &&
                                    <Search value={false} placeholder={'Sony a7'} category="All" onSearchResults={handleSearchResults} />
                                }
                            </div>
                            <div className="filter-on">
                                {!filter && isSmallScreen &&
                                    <div className="toggle-filter" onClick={() => setFilter(true)}>
                                        <FilterAltIcon className='filter-icon' fontSize='large' />
                                    </div>
                                }
                                {filter && isSmallScreen &&
                                    <div className="toggle-filter" onClick={() => setFilter(false)}>
                                        <FilterAltOffIcon className='filter-icon' fontSize='large' />
                                    </div>
                                }
                            </div>
                        </div>
                        {!filter && !isSmallScreen &&
                            <>
                                <div className="filter-items" style={{ display: 'none' }}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={brand}
                                                label="Brand"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={'Sony'}>Sony</MenuItem>
                                                <MenuItem value={'Canon'}>Canon</MenuItem>
                                                <MenuItem value={'Nikon'}>Nikon</MenuItem>
                                                <MenuItem value={'Leica'}>Leica</MenuItem>
                                                <MenuItem value={'Fuji'}>Fuji</MenuItem>
                                                <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                                                <MenuItem value={'Tamron'}>Tamron</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={brand}
                                                label="Brand"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={'Sony'}>Sony</MenuItem>
                                                <MenuItem value={'Canon'}>Canon</MenuItem>
                                                <MenuItem value={'Nikon'}>Nikon</MenuItem>
                                                <MenuItem value={'Leica'}>Leica</MenuItem>
                                                <MenuItem value={'Fuji'}>Fuji</MenuItem>
                                                <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                                                <MenuItem value={'Tamron'}>Tamron</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items store">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Store</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={store}
                                                label="Store"
                                                onChange={handleStore}
                                            >
                                                <MenuItem value={'Fred Miranda'}>Fred Miranda</MenuItem>
                                                <MenuItem value={'Reddit'}>Reddit</MenuItem>
                                                <MenuItem value={'Ebay'}>eBay</MenuItem>
                                                <MenuItem value={'Keh'}>Keh</MenuItem>
                                                <MenuItem value={'Adorama'}>Adorama</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items store">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sort}
                                                label="Sort"
                                                onChange={handleSort}
                                            >
                                                <MenuItem value={'high'}>High to Low</MenuItem>
                                                <MenuItem value={'low'}>Low to High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-price">
                                    <Slider
                                        aria-label="Always visible"
                                        defaultValue={800}
                                        max={8000}
                                        step={100}
                                        marks={marks}
                                        style={{ 'color': '#FA8071' }}
                                        valueLabelDisplay="on"
                                        onChange={handlePriceChange}
                                        value={[minPrice, maxPrice]}
                                    />
                                </div>
                            </>
                        }

                        {filter && isSmallScreen &&
                            <>
                                <div className="filter-items" style={{ display: 'block' }}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={brand}
                                                label="Brand"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={'Sony'}>Sony</MenuItem>
                                                <MenuItem value={'Canon'}>Canon</MenuItem>
                                                <MenuItem value={'Nikon'}>Nikon</MenuItem>
                                                <MenuItem value={'Leica'}>Leica</MenuItem>
                                                <MenuItem value={'Fuji'}>Fuji</MenuItem>
                                                <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                                                <MenuItem value={'Tamron'}>Tamron</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={brand}
                                                label="Brand"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={'Sony'}>Sony</MenuItem>
                                                <MenuItem value={'Canon'}>Canon</MenuItem>
                                                <MenuItem value={'Nikon'}>Nikon</MenuItem>
                                                <MenuItem value={'Leica'}>Leica</MenuItem>
                                                <MenuItem value={'Fuji'}>Fuji</MenuItem>
                                                <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                                                <MenuItem value={'Tamron'}>Tamron</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items store">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Store</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={store}
                                                label="Store"
                                                onChange={handleStore}
                                            >
                                                <MenuItem value={'Fred Miranda'}>Fred Miranda</MenuItem>
                                                <MenuItem value={'Reddit'}>Reddit</MenuItem>
                                                <MenuItem value={'Ebay'}>eBay</MenuItem>
                                                <MenuItem value={'Keh'}>Keh</MenuItem>
                                                <MenuItem value={'Adorama'}>Adorama</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-items store">
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sort}
                                                label="Sort"
                                                onChange={handleSort}
                                            >
                                                <MenuItem value={'high'}>High to Low</MenuItem>
                                                <MenuItem value={'low'}>Low to High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="filter-price">
                                    <Slider
                                        aria-label="Always visible"
                                        defaultValue={800}
                                        max={8000}
                                        step={100}
                                        marks={marks}
                                        style={{ 'color': '#FA8071' }}
                                        valueLabelDisplay="on"
                                        onChange={handlePriceChange}
                                        value={[minPrice, maxPrice]}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    {endpoint != "/products" && loading == false && products.length > 0 && products.length != undefined &&
                        <div className="product-count">
                            We currently have <span className="products-total">{products.length} {title} Products</span>
                        </div>
                    }
                    {filtered === false && loading === false && (
                            <>
                        {products.length === 0 && (
                        <div className="error">
                            No results found
                        </div>
                        )}

                        {search === false && products.length >= 1 && (
                        <div className="products-all">
                            <Suspense fallback={<div>Loading...</div>}>
                            <LazyCard showHeart={heart} products={products} likes={likes} endpoint={endpoint} />
                            </Suspense>
                        </div>
                        )}

                        {search === true && products.length >= 1 && (
                        <div className="products-all">
                            <Suspense fallback={<div>Loading...</div>}>
                            <LazyCard showHeart={heart} products={searchResults} likes={likes} endpoint={endpoint} />
                            </Suspense>
                        </div>
                        )}

                       
                                        
                        </>
                    )}
 {filtered === true && loading === false && filteredProducts.length > 1 && (
                        <div className="products-all">
                            <Suspense fallback={<div>Loading...</div>}>
                            <LazyCard showHeart={heart} products={filteredProducts} likes={likes} endpoint={endpoint} />
                            </Suspense>
                        </div>
                        )}
                    {show == true &&
                        <div className="page-navigation">
                            {previousPage &&
                                <div className="previous-page">
                                    <button onClick={loadLess} className='btn-large gradient-bg page'>Previous Page</button>
                                </div>
                            }
                            <div className="load-more">
                                <button onClick={loadMore} className='btn-large gradient-bg page'>Next Page</button>
                            </div>
                        </div>

                    }
                </>
            }
            {loading == true &&
                <Loader />
            }
            {error == true &&
                <Connection />
            }
            {filtered == true &&
                <div className="reset-filters">
                    <button className="btn-large gradient-bg reset-button" onClick={resetData}>Clear Filters</button>
                </div>
            }
            {filtered == true && filteredProducts.length == 0 &&
                <Results />
            }
        </div>
    )
}

export default ProductAll