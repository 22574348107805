import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import './edit.scss'
const Edit = () => {
    const [post, setPost] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [validating, setValidating] = useState(false);
    const [success, setSuccess] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const postId = window.location.pathname.split('/')[2];  // Get the post ID from the URL
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        async function fetchPost() {
            setLoading(true)
            try {
                const response = await fetch(`https://api.cameracove.com/article/${postId}`);
                const data = await response.json();
                setPost(data.post);
                setTitle(data.post.title)
                setContent(data.post.message)
                setFile(data.post.updatedImage)
                setPreview(data.post.updatedImage)
                setLoading(false)
            } catch (error) {
                console.error(error);
                setLoading(false)
            }
        }
        fetchPost();
    }, []);
    const handleChange = (event) => {
        // Get the file from the input
        const newFile = event.target.files[0];

        // Set the file in the state
        setFile(newFile);

        // Create a preview
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(newFile);
    }

    const deletePost = async (event) => {
        try {
            const response = await fetch(`https://api.cameracove.com/delete-post/${postId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            if (response.status == 200) {
                const data = await response.json();
                setDeleted(true)
                setValidating(false)
            } else {
                const error = await response.json();
                setError(error.error);
                setValidating(false)
                setDeleted(false)
            }
        } catch (error) {
            setError(error.error);
            setValidating(false)
        }
    }

    const submitBlog = async (event) => {
        event.preventDefault();
        if (file != null) {
            setValidating(true)
            const formData = new FormData();
            formData.append('title', title);
            formData.append('message', content);
            formData.append('file', file);
            try {
                const response = await fetch(`https://api.cameracove.com/edit-blog-post/${postId}`, {
                    method: 'PUT',
                    body: formData,
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                    }
                });

                if (response.status == 200) {
                    const data = await response.json();
                    setSuccess(true)
                    setValidating(false)
                } else {
                    const error = await response.json();
                    setError(error.error);
                    setValidating(false)
                    setSuccess(true)
                }
            } catch (error) {
                setError(error.error);
                setValidating(false)
            }
        } else {
            setValidating(false)
            setError("Error. Please upload a profile picture.")
        }
    }
    return (
        <div className='edit-wrapper-post'>
            <div className="write-new-wrapper">
                {success == false &&
                <>
                    <form onSubmit={submitBlog} className='edit-form-main'>
                        <div className="file">
                            {preview &&
                                <div className="image-preview">
                                    <img src={preview} alt="Preview" className='preview-image' />
                                </div>
                            }
                            <span className="field-type">Header Image</span>
                            <input type="file" onChange={handleChange} className='file-upload' />
                        </div>
                        <div className="content">
                            <span className="field-type">Article Title</span>
                            <input type="text" name="title" placeholder='Enter title' value={post.title} className='text-input' value={title} onChange={(event) => setTitle(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Article Content</span>
                            <textarea name="content" cols="30" rows="10" placeholder='Contents' className='content-area' value={content} onChange={(event) => setContent(event.target.value)}>{post.message}</textarea>
                        </div>
                        {validating === false &&
                            <input type="submit" className="btn-large gradient-bg submit-button" placeholder='Submit Article' onClick={submitBlog}/>
                        }
                        {validating === true &&
                            <button className="btn-large gradient-bg submit-button"><CircularProgress color="inherit" className='validating' /></button>
                        }
                        
                    </form>
                    <div className="delete-post">
                            {validating === false &&
                                <button className="btn-large gradient-bg-blue delete-button" onClick={deletePost}>Delete Post</button>
                            }
                            {validating === true &&
                                <button className="btn-large gradient-bg-blue delete-button"><CircularProgress color="inherit" className='validating' /></button>
                            }
                        </div>
                </>
                }

            </div>
            {success == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your blog has been updated.
                    </div>
                    <div className="buttons">
                        <button className="btn-large gradient-bg" onClick={() => setSuccess(false)}>Write Another</button>
                        <button className="btn-large gradient-bg" onClick={() => window.location.href = `/article/${postId}`}>View Post</button>
                    </div>
                </div>
            }
            {deleted == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your blog has been deleted.
                    </div>
                    <button className="btn-large gradient-bg" onClick={() => navigate(`/write`)}>Back to Posts</button>
                </div>
            }
        </div>
    )
}

export default Edit