import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import productExample from '../../../assets/product-example.jpeg';

const ProductImage = ({ product }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setIsLoading(true);
  }, [product]);

  const handleImageLoad = () => {
    setIsLoaded(true);
    setIsLoading(false);
  };

  const getImageSource = () => {
    if (product.on_site && product.imageUrls && product.imageUrls.length > 0) {
      return product.imageUrls[0];
    } else if (product.image && product.image !== 'null') {
      return product.image;
    } else if (product.image === 'null' || product.image.includes('imgur')) {
      return productExample;
    }
    return null;
  };

  const imageSource = getImageSource();

  return (
    <>
      {isLoading && 
        <div style={{ width: '100%' }
        }>
            <Skeleton variant="rounded" className="product-image-top" />
 
        </div>
      }
      {imageSource && (
        <img
          src={imageSource}
          alt="product image"
          className="product-image-top"
          style={{ display: isLoaded ? 'block' : 'none' }}
          onLoad={handleImageLoad}
        />
      )}
    </>
  );
};

export default ProductImage;