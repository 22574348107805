import React, { useState, useEffect } from 'react';
import Article from '../suggestions/article/Article';
import './write.scss'
import '../writer/writer.scss'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cookies from 'js-cookie';

const Write = () => {
    const [show, setShow] = useState(true);
    const [posts, setPosts] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [validating, setValidating] = useState(false);
    const [success, setSuccess] = useState(false);
    document.title = "Writer Dashboard"

    const handleChange = (event) => {
        // Get the file from the input
        const newFile = event.target.files[0];

        // Set the file in the state
        setFile(newFile);

        // Create a preview
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(newFile);
    }

    const submitBlog = async (event) => {
        event.preventDefault();
        if (file != null) {
            setValidating(true)
            const formData = new FormData();
            formData.append('title', title);
            formData.append('message', content);
            formData.append('file', file);
            try {
                const response = await fetch('https://api.cameracove.com/submit-blog-post', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                    }
                });

                if (response.status == 200) {
                    const data = await response.json();
                    setSuccess(true)
                } else {
                    const error = await response.json();
                    setError(error.error);
                    setValidating(false)
                    setSuccess(true)
                }
            } catch (error) {
                setError(error.error);
                setValidating(false)
            }
        } else {
            setValidating(false)
            setError("Error. Please upload a profile picture.")
        }
    }
    useEffect(() => {
        const fetchUserPosts = async () => {
            const response = await fetch('https://api.cameracove.com/get-user-posts', {
                method: 'GET',
                headers: {
                    Authorization: `${Cookies.get('auth')}`,
                }
            });
            if (response.status != 200) {
                window.location.href = "/login"
            }
            const data = await response.json();
            setPosts(data.userPosts);
        }
        fetchUserPosts();
    }, []);
    return (
        <div className='write-wrapper'>
            <div className="form-header">Write an Article</div>
            {success == false &&
                <div className="write-new-wrapper">
                    <form onSubmit={submitBlog} className='write-form-main'>
                        <div className="file">
                            {preview &&
                                <div className="image-preview">
                                    <img src={preview} alt="Preview" className='preview-image' />
                                </div>
                            }
                            <span className="field-type">Header Image</span>
                            <input type="file" onChange={handleChange} className='file-upload' />
                        </div>
                        <div className="title">
                            <span className="field-type">Article Title</span>
                            <input type="text" name="title" placeholder='Enter title' className='text-input write-input' value={title} onChange={(event) => setTitle(event.target.value)} />
                        </div>
                        <div className="content">
                            <span className="field-type">Article Content</span>
                            <textarea name="content" cols="30" rows="10" placeholder='Contents' className='content-area' value={content} onChange={(event) => setContent(event.target.value)}></textarea>
                        </div>
                        {validating === false &&
                            <input type="submit" className="btn-large gradient-bg submit-button" placeholder='Submit Article' />
                        }
                        {validating === true &&
                            <button className="btn-large gradient-bg submit-button"><CircularProgress color="inherit" className='validating' /></button>
                        }
                    </form>
                </div>
            }
            {success == true &&
                <div className="success">
                    <CheckCircleIcon className='success-icon' />
                    <div className="success-message">
                        Your blog has been uploaded. Refresh to view.
                    </div>
                    <button className="btn-large gradient-bg" onClick={() => setSuccess(false)}>Write Another</button>
                </div>
            }
                <div className="user-posts">
                    {show == true &&
                    <>            
                        <div className="form-header">
                            Your Posts 
                            <Tooltip title="Hide" className='show-hide'>
                                <IconButton>
                                    <HideSourceIcon onClick={() => setShow(false)}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        
                        {posts != null &&
                            <div className="articles-wrapper-internal">
                                <div className="article-internal">
                                    <Article data={posts} edit={true} className='article' />
                                    <hr />
                                </div>
                            </div>
                        }
                    </>
                    }
                    {show == false &&
                        <div className="show-header">
                            <Tooltip title="Show Posts" className='show-hide'>
                                <IconButton>
                                    <VisibilityIcon onClick={() => setShow(true)}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </div>

        </div>
    )
}

export default Write