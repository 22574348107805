import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Writer from '../components/writer/Writer.jsx'

const Verification = () => {
  return (
    <div className='verification-wrapper'>
        <Navbar />
        <section className="header-section login">
          <Writer />
        </section>
        <Footer />
    </div>
  )
}

export default Verification