import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Contact from '../components/contact/Contact'

const ContactPage = () => {
  return (
    <div className='product-page page'>
        <Navbar />
        <section className="header-section product">
          <Contact />
        </section>
        <Footer />
    </div>
  )
}

export default ContactPage