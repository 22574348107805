import { useState } from 'react';
import './footer.scss'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState(false);

    const handleClickOpen = () => {
            setOpen(true);
        };

    const handleClose = () => {
        setOpen(false);
    };
    const [subscribed, setSubscribed] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const response = await fetch('https://api.cameracove.com/newsletter', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
          });
    
          if (response.status == 200) {
            const data = await response.json();
            setSubscribed(true)
            setOpen(true)
          } else {
            const error = await response.json();
            setError(error.error);
            setOpen(true)
          }
        } catch (error) {
            setError(error.error);
            setOpen(true)
        }
      };
  return (
    <div className='footer-wrapper'>
        <div className="footer-contents">
                <div className="footer-left">
                    <div className="company-name" onClick={() => navigate(`/`, { replace: true })}>
                        <div className="first">
                        Camera
                        </div>
                        <div className="second">
                        Cove
                        </div>
                    </div>
                    <div className="company-description">
                        Find used camera gear at great prices. We compare thousands of items for you!
                    </div>
                </div>
                <div className="footer-center">
                    <div className="links">
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/"
                        >
                        Home
                        </NavLink>
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/products"
                        >
                        Products
                        </NavLink>
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/suggestions"
                        >
                        Suggestions
                        </NavLink>
                    </div>
                    <div className="links">
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/terms"
                        >
                        Terms & Conditions
                        </NavLink>
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/about"
                        >
                        About
                        </NavLink>
                        <NavLink
                        exact
                        activeClassName="navlink active"
                        className="navlink"
                        to="/contact"
                        >
                        Contact Us
                        </NavLink>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="information">Stay up to date about Camera Cove!</div>
                    {subscribed == false &&
                        <form onSubmit={handleSubmit}>
                            <div className="register-form">
                                <input type="text" placeholder='Enter email' name='email' className='register-email' onChange={e => setEmail(e.target.value)}/>
                                <input type="submit" className="btn gradient-bg register" placeholder='Join' />
                            </div>
                        </form>
                    }
                    {subscribed == true &&
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Thank you for joining!
                            </Alert>
                        </Snackbar>
                    } 
                    {error != null &&
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {error}
                            </Alert>
                        </Snackbar>
                    } 
                </div>

        </div>
        <div className="footer-copyright">
                &#169; 2023 Camera Cove LLC
            </div>
    </div>
  )
}

export default Footer