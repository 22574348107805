import React from 'react'
import SellerDashboard from '../components/dashboard/SellerDashboard.jsx'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'

const Seller = () => {
  return (
    <div className='seller-page page'>
        <Navbar />
        <section className="header-section dashboard-seller">
            <SellerDashboard />
        </section>
        <Footer />
    </div>
  )
}

export default Seller