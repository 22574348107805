import React from 'react'
import './error.scss'
import { useNavigate } from "react-router-dom";

const Error = () => {
    const navigate = useNavigate()
    document.title = "404 Error";
  return (
    <div className='error-wrapper'>
        <div className="error-content">
            <div className="error-header">
                404
            </div>
            <div className="sub-text">
                Page Not Found!
            </div>
            <div className="below-sub">
                We can't seem to find this page. Please try again.
            </div>
            <div className="back-home">
                <button className="btn-large gradient-bg back-home-btn" onClick={() => navigate('/')}>Back Home</button>
            </div>
        </div>
        
    </div>
  )
}

export default Error