import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Skeleton } from '@mui/material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './product.scss'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import example from '../../assets/product-example.jpeg'
import Loader from '../loader/Loader';
import Connection from '../error/Connection';
import Unavailable from '../error/Unavailable';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Comparison from './Compare/Comparison';
const Product = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [error, setError] = useState(false);
    const [login, setLogin] = useState(false);
    const [product, setProduct] = useState(null);
    const [compare, setCompare] = useState(false);
    const [compareProduct, setCompareProduct] = useState([]);

    const id = window.location.pathname.split('/')[2];
    useEffect(() => {
        window.scrollTo(0, 0);
        function checkAuth() {
            return !!Cookies.get('auth');
        }
        if (checkAuth) {
            setLogin(true);
        }
    }, []);

    useEffect(() => {
        const fetchProduct = () => {
          return fetch(`https://api.cameracove.com/product/${id}`)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                throw new Error(`HTTP error ${response.status}`);
              }
            });
        };
      
        const fetchLikes = () => {
          return fetch(`https://api.cameracove.com/likes`, {
            headers: {
              Authorization: `${Cookies.get('auth')}`,
            }
          })
          .then((response) => {
            if (response.status === 200) {
                if (response.headers.get('content-length') === '0') {
                    return { user: true };
                  }  else {
                  return response.json();
                }
            } else {
                return {user: false}
            }
          });
        };
      
        Promise.all([fetchProduct(), fetchLikes()])
          .then(([productData, likesData]) => {
            setProduct(productData);
            if (productData.status && likesData.status) {
                if (likesResponse.status == 200 && likes.length != 0) {
                    setLoading(false);
                }
                else if (likesResponse.status != 200 && likes.length == 0) {
                    setLoading(false)
                }
              // Set the loading state to false if both API calls have returned a status
            }
            if (likesData.user == "Unauthorized" || likesData.user == false) {
                setShow(false)
            }else {
                const hasId = Object.values(likesData).some(like => like.id === id);
                if (hasId) {
                  setIsLiked(true);
                }
                setShow(true);
            }
            
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setError("show");
          });
      }, []);
      
    const toggleLike = async (event) => {
        event.preventDefault();
        if (isLiked == false) {
            try {
                const response = await fetch('https://api.cameracove.com/like', {
                    method: 'POST',
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        condition: product.condition,
                        description: product.description,
                        id: product.id,
                        image: product.image,
                        item: product.item,
                        price: product.price,
                        type: product.type,
                        url: product.url,
                        uid: product.uid,
                        onsite: false
                    })
                });
                const data = await response.json();
                setIsLiked(!isLiked);
            } catch (error) {
                console.error(error);
            }
        }
        else {
            async function removeLike() {
                const response = await fetch(`https://api.cameracove.com/like/${id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `${Cookies.get('auth')}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (response.ok) {
                    setIsLiked(false);
                } else {
                    console.error('Error removing like');
                }
            }
            removeLike()
        }

    }
    function handleClick() {
        navigate(-1)
    }

    function compareItem(item, price, store) {
        setCompare("loading")
        // async get request to compare endpoint with item and price and store as query params
        async function fetchCompare() {
            try {
                const response = await fetch(`https://api.cameracove.com/compare?name=${item}&price=${price}&store=${store}`);
                if (response.status == 200) {
                    const data = await response.json();
                    setCompareProduct(data.similarProducts);
                    setCompare(true)
                } else {
                    const data = await response.json();
                    console.log(data)
                    setError(data);
                    setCompare(false)
                }
            } catch (error) {
                setCompare(false)
            }
        }
        fetchCompare();
    }

    return (
        <div className="main-wrapper-product">
            <div className='product-wrapper'>
                {loading == false && error != "show" && product != null &&
                    <>
                        <Helmet>
                            <title>{product.item}</title>
                        </Helmet>
                        <div className="product-image">
                            <div className="pagination">
                                <a onClick={handleClick} className='back-url'>Go back</a>
                            </div>
                            {product.image != "null" && !product.image.includes("imgur") &&
                                <>
                                    <img src={product.image} alt="product image" className="product-image-large" />
                                </>
                            }
                            {product.image === "null" &&
                                <>
                                    <img src={example} alt="product image" className="product-image-large" />
                                </>
                            }
                            {product.image != "null" && product.image.includes("imgur") &&
                                <img src={example} alt="product image" className="product-image-large" />
                            }

                        </div>
                        <div className="product-details">
                            <div className="original-site">{product.store}</div>
                            <div className="product-title">{product.item}</div>
                            <div className="product-notes">
                                <span className="notes-header">Product Notes</span>
                                <span className="product-notes-text">
                                    {product.description}
                                    <br></br>
                                    {product.condition}
                                </span>
                            </div>
                            {product.matches > 1 &&
                                <div className="compare">
                                    <span className="compare-header">Comparison Data</span>
                                    <div className="number-compared">We Compared: <span className="count">{product.matches} items</span></div>
                                    <div className="average-price">Average price of the item: <span className="count">${product.average_price}</span></div>
                                    <div className="rating">Price score: <span className={product.rating} id="rating-score">{product.rating}</span></div>
                                    <div className="rating"><span className='saved'>${Math.round(product.average_price - product.price)} Lower!</span></div>
                                </div>
                            }

                            <div className="price">
                                {product.price != null &&
                                    <>
                                        ${product.price}
                                    </>
                                }

                            </div>
                            <div className="action-buttons">
                                <div className="foot-buttons">
                                    <div className="view-product">
                                        <button className="btn-large view-button gradient-bg" onClick={() => window.open(`${product.url}`)}>View Item <ArrowCircleRightIcon className='internal-icon' /></button>
                                    </div>
                                    {show == true &&
                                        <div className="like-product">
                                            <button className="btn-large like-button gradient-bg" onClick={toggleLike} style={{ color: isLiked ? 'red' : 'white' }}><FavoriteIcon /></button>
                                        </div>
                                    }

                                </div>
                                {login == true &&
                                    <div className="compare-button">
                                        <button className="btn-large compare-button gradient-bg-blue" onClick={() => compareItem(product.item, product.price, product.store)}>Find Similar <AutoFixHighIcon /></button>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {loading == true &&
                    <Loader />
                }
                {loading == false && error == true &&
                    <>
                        <Helmet>
                            <title>Error</title>
                        </Helmet>
                        <Connection />
                    </>
                }
                {loading == false && error == "show" &&
                    <>
                        <Helmet>
                            <title>Unavailable</title>
                        </Helmet>
                        <Unavailable />
                    </>
                }

            </div>
            <div className="comparison-content">
                {compare == "loading" &&
                    <div className="loading-display">
                        <div className="compare-wrapper">
                            {Array.from({ length: 3 }).map((_, index) => (
                                <Skeleton key={index} height={300} width={300}/>
                            ))}
                        </div>
                        
                    </div>
                }
                {compare == true && compareProduct.length == 0 &&
                    <div className="nothing-to-see">
                        <h3 className="no-items">No items found</h3>
                    </div>
                }
                {compare == true && compareProduct.length >= 1 &&
                    <div className="items-comparison">
                        <Comparison item={compareProduct}/>
                    </div>
                }
                {compare == false && error &&
                <div className="nothing-to-see">
                    <h3 className="no-items">{error.error}</h3>
                </div>
                }
            </div>
            
        </div>

    )
}

export default Product