import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { formatDistanceToNow } from 'date-fns';
import './chat.scss';
import Loader from '../loader/Loader';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ReviewsIcon from '@mui/icons-material/Reviews';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import QuoteModal from './QuoteModal';
import { Tooltip } from '@mui/material';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [conversation, setConversation] = useState(null);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  let totalPrice = 0;

  const conversationId = window.location.pathname.split('/chat/')[1];
  document.title = 'Chat';

  useEffect(() => {
    // Fetch the conversation when the component mounts
    const fetchConversation = async () => {
      try {
        const response = await fetch(`https://api.cameracove.com/conversations/${conversationId}`, {
          headers: {
            Authorization: `${Cookies.get('auth')}`,
          },
        });
        const data = await response.json();
        if (response.status != 200) {
            window.location.href = '/login';
            setLoading(false);
            return;
        }
        setConversation(data.conversation);
        setUser(data.conversation.isSender);
        setUserId(data.uid);
        setStatus(data.conversation.status);

        // Convert messages object to an array
        const messagesArray = Object.values(data.conversation.messages || {});

        setMessages(messagesArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchConversation();
  }, [conversationId]);

  const markComplete = async (conversationId, messageId) => {
    //post to /update-quote-status with conversation id as param in url 
    try {
        const response = await fetch(`https://api.cameracove.com/update-quote-status/${conversationId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${Cookies.get('auth')}`,
            },
            body: JSON.stringify({
                status: 'Completed',
                messageId: messageId
            }),
        });
        const data = await response.json();
        setStatus(data.status);
        //update the chat 
        setConversation(data.conversation);
        const messagesArray = Object.values(data.conversation.messages || {});

        setMessages(messagesArray);
    } catch (error) {
        console.error(error);
    }
    }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send the new message
      const response = await fetch('https://api.cameracove.com/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${Cookies.get('auth')}`,
        },
        body: JSON.stringify({
          message: newMessage,
          conversationId: conversationId,
          type: 'chat',
        }),
      });
      const data = await response.json();

      // Update the conversation with the new messages
      const updatedConversation = data.conversation;
      const updatedMessagesArray = Object.values(updatedConversation.messages || {});
      setMessages(updatedMessagesArray);
      setNewMessage('');
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setShowQuote(false);
    };

  const getProduct = (productId) => {
    window.location.href = `/product/us/${productId}`;
  };

  const updateStatus = async (productId, status) => {
    try {
      // Post to the /update-product route with the productID and the availability status which should be completed here
      const response = await fetch(`https://api.cameracove.com/update-product-status`, {
        body: JSON.stringify({
          productId: productId,
          availabilityStatus: status,
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${Cookies.get('auth')}`,
        },
      });
      const data = await response.json();
      setStatus(data.status);
      setSuccess(true);
      if (status === 'Completed') {
        setNewMessage('Thank you for your purchase! Please remember to leave a review!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  if (!conversation) {
    return (
      <div>
        <Loader />.
      </div>
    );
  }

  return (
    <div className="chat-wrapper">
      <div className="go-back">
        <a href="/conversations" className="go-back-link">
          Go back to messages
        </a>
      </div>
      <div className="seller-profile">
        <div className="seller-profile-main">
          <div className="seller-profile-image">
            <img
              src={conversation[user ? 'recipientProfileImage' : 'senderProfileImage']}
              className="profile-picture"
              alt="seller profile"
            />
          </div>
          <div className="seller-name">
            <h2>{user ? conversation.recipientDisplayName : conversation.senderDisplayName}</h2>
            {conversation.isSeller && 
              <a href={`/user/${conversation.buyerId}`} className="profile-link">
                View profile
              </a>
            }
            {!conversation.isSeller && 
                <a href={`/user/${conversation.sellerId}`} className="profile-link">
                  View profile
                </a>
              }
          </div>
        </div>
        {conversation.itemName && (
          <div className="item-details" onClick={() => getProduct(conversation.itemId)}>
            <div className="item-name">
              <h2>{conversation.itemName}</h2>
              <p className="price">${conversation.itemPrice}</p>
            </div>
            <div className="item-image">
              <img src={conversation.itemImage} className="item-picture" alt="item" />
            </div>
          </div>
        )}
      </div>
      {!conversation.isSeller && (
        <>
          <div className="buyer-notice">
            <p>
              Please remember to always only use safe payment methods. Be careful when sending payments and always
              verify the destination. <b>Camera Cove</b> is not responsible for any mishaps within the purchase process.
              For more information, please view our <a href="/terms" id="link-buyer">terms</a>.
            </p>
          </div>
          <div className="buyer-action">
            <div className="leave-feedback">
              <button
                className="review-button action-button btn-large gradient-bg"
                onClick={() => (window.location.href = `/seller/${conversation.sellerId}?review=true`)}
              >
                <ReviewsIcon /> Write a review
              </button>
            </div>
          </div>
        </>
      )}
      {conversation.isSeller === true && (
        <div className="seller-actions">
          <div className="update-status">
            <Tooltip title="Delete">
            <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(conversation.itemId, 'Pending')}>
                <DeleteIcon />
            </button>
            </Tooltip>
            <Tooltip title="Mark Available">
            <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(conversation.itemId, 'Available')}>
                <ShoppingBagIcon />
            </button>
            </Tooltip>
            <Tooltip title="Mark Pending">
            <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(conversation.itemId, 'Pending')}>
                <AccessTimeIcon />
            </button>
            </Tooltip>
            <Tooltip title="Mark as Completed">
            <button className="action-button btn-large gradient-bg" onClick={() => updateStatus(conversation.itemId, 'Completed')}>
                <CheckCircleIcon />
            </button>
            </Tooltip>
            <div className="quote-action">
              <button
                className="quote-button action-button btn-large gradient-bg"
                onClick={() => {
                    setShowQuote(true);
                }}
              >
                <div className="quote-body">
                    <LocalOfferIcon /> Submit a Quote
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="success-message">
          <p>Product status updated successfully! Product is now: {status}</p>
        </div>
      )}
      <hr></hr>
      <br></br>
      {messages.map((message, index) => {
        const isSender = message.senderId === userId;
        const isRecipient = message.recipientId === userId;
        const messageClassName = isSender ? 'right' : 'left';

        // Check if the message is the last sent message by the current user
        const isUserSender = conversation.senderId === userId;
        const isUserRecipient = conversation.recipientId === userId;
        const isLastSentMessage = isSender && index === messages.length - 1;

        if (message.type && message.type === 'quote') {
            totalPrice = parseInt(message.message.itemPrice) + parseInt(message.message.shippingPrice);
        }
        // Determine the message status (Read or Delivered)
        let messageStatus = '';
        if (isLastSentMessage) {
          const isCurrentUserSender = message.senderId === userId;

          if (isUserSender && isCurrentUserSender) {
            // Check status of message in lastMessage
            messageStatus = message.readByRecipient ? 'Read' : 'Delivered';
          } else if (isUserRecipient && isCurrentUserSender) {
            messageStatus = message.readBySender ? 'Read' : 'Delivered';
          }
        }

        return (
          <div key={index} className={messageClassName}>
            {message.type == 'chat' && 
                <div className="message">
                    <div className="message-container">
                        {typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}
                        </div>
                    <div className="message-info">
                        <span className="message-date">{formatDate(message.sentAt)}</span>
                        {isLastSentMessage && (
                        <>
                            <span className="message-bullet">•</span>
                            <span className={`message-date ${messageStatus.toLowerCase()}`}>{messageStatus}</span>
                        </>
                        )}
                    </div>
                </div>
            }
            
            {message.type === 'quote' && 
            <div className="message">
                <div className="quote-message-container">
                        <h2>Quote:</h2>
                        <p>Item Price: <b>${message.message.itemPrice}</b></p>
                        <p>Shipping Price: <b>${message.message.shippingPrice}</b></p>
                        <div className="total">
                            <p>Total: <b>${totalPrice}</b></p>
                        </div>
                        <p>Status: <b>{message.message.status}</b></p>
                        {message.senderId != userId &&
                            <div className="reminder">
                                Reminder, please only use safe payment methods such as PayPal Goods and Services.
                                Again, <b>Camera Cove</b> is not responsible for any mishaps within the purchase process.
                            </div>
                        }
                        {message.senderId === userId &&
                            <div className="reminder">
                                Your quote has been sent. Please wait for the buyer to confirm the payment. Always use safe payment methods and insure sales.
                                Again, <b>Camera Cove</b> is not responsible for any mishaps within the purchase process.
                            </div>
                        }
                </div>
                {message.senderId != userId && message.message.status === 'Pending' &&
                    <div className="quote-actions">
                        <button className="action-button complete-quote btn-large gradient-bg" onClick={() => markComplete(conversationId, message.messageId)}>
                            <CheckCircleIcon />
                            Mark Completed
                        </button>
                    </div>
                }
            </div>
                
            }
          </div>
        );
      })}
      <form onSubmit={handleSubmit} className="chat-form">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          required
          className="chat-input"
          placeholder="Write a message..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
          }}
        />
        <button type="submit" className="submit-chat">
          <SendIcon />
        </button>
      </form>
      {showQuote &&
        <div className="quote-modal">
            <QuoteModal onClose={closeModal} conversationId={conversationId} />
        </div>
      }
    </div>
  );
};

export default Chat;
