import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Subscribe from '../components/subscription/Subscription'

const SubscriptionPage = () => {
  return (
    <div className='about-page page'>
        <Navbar />
        <section className="header-section subscription">
          <Subscribe />
        </section>
        <Footer />
    </div>
  )
}

export default SubscriptionPage