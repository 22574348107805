import {useState} from 'react'
import './header.scss'
import clipart from '../../assets/background.jpg'
import Search from '../search/Search'
import ItemCards from './welcome-cards/ItemCards'
import Process from './welcome-process/Process'
import Sites from './welcome-sites/Sites'
import Skeleton from '@mui/material/Skeleton';
const Header = () => {
    const [loading, setLoading] = useState(true)
    const imageLoaded = () => {
        setLoading(false)
    }
  return (
    <div className='header-wrapper wrapper'>
        <div className="header-content">
            <div style={{ display: loading ? 'none' : 'block' }}>
                {loading == true &&
                    <Skeleton variant="rectangular" className='search-background page-background skeleton' height={600} />
                }
                {loading == false &&
                    <img src={clipart} alt="background clipart" className='search-background page-background'/>
                }
                <img src={clipart} alt="background clipart" 
                    className='search-background page-background' 
                    onLoad={imageLoaded}
                    style={{ display: loading ? 'block' : 'none' }}
                />
            </div>
            <div className="main-content centered">
                <div className="text-headers">
                    <div className="header">
                        Welcome, Looking to Buy Used Gear?
                    </div>
                    <div className="subheader">Find anything you need here!</div>
                </div>
                <div className="search">
                    <Search value={false} placeholder={'Sony a7'} category="All" />
                </div>
            </div>
        </div>
        <div className="items">
            <ItemCards />
        </div>
        <div className="process">
            <Process />
        </div>
        <div className="sites">
            <Sites />
        </div>
    </div>
  )
}

export default Header