import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import About from '../components/about/About'
import AI from '../components/ai/AI'

const AIPage = () => {
  return (
    <div className='about-page page'>
        <Navbar />
        <section className="ai-section about">
          <AI />
        </section>
        <Footer />
    </div>
  )
}

export default AIPage