import React, { useState } from 'react';
import './seller-form.scss';
import Cookies from 'js-cookie';
import { CircularProgress } from '@mui/material';


const SellerSignup = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [validating, setValidating] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setValidating(true)
        try {
            const response = await fetch('https://api.cameracove.com/seller-signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${Cookies.get('auth')}`,
                },
                body: JSON.stringify({ email, name, phoneNumber, description })
            });

            if (!response.ok) {
                setError(error);
                setValidating(false)
                return;
            }

            const data = await response.json();
            setSuccess(true)
            setValidating(false)
            
        } catch (error) {
            console.error(error);
            setError(error);
            setValidating(false)
        }
    };


    return (
        <div className="seller-signup-wrapper">
            <form onSubmit={handleSubmit}>
                <h2>Seller Signup</h2>
                <div className="form-contents">
                <div className="item-wrapper-input">
                    Email:
                    <input type="email" className='seller-input' value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>

                <div className="item-wrapper-input">
                    Name:
                    <input type="text" className='seller-input' value={name} onChange={(e) => setName(e.target.value)} required />
                </div>

                <div className="item-wrapper-input">
                    Phone Number:
                    <input type="tel" className='seller-input' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                </div>

                <div className="item-wrapper-input">
                    Personal Description:
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>
                {validating == true &&
                    <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating'/></button>
                    }
                {validating == false &&
                    <button type="submit" className='btn-large sign-submit gradient-bg'>Submit</button>
                }
                </div>

                
            </form>
            {error != null &&
                <div className="error">
                    <div className="error-text">There was an issue with your request.</div>
                </div>
            }
            {success == true &&
                <div className="success">
                    <div className="success-text">Your request has been submitted.</div>
                </div>
            }
        </div>

    );
};

export default SellerSignup;
