import React from 'react'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Edit from '../components/edit/Edit.jsx'

const EditBlog = () => {
  return (
    <div className='edit-wrapper'>
        <Navbar />
        <section className="header-section edit">
          <Edit />
        </section>
        <Footer />
    </div>
  )
}

export default EditBlog