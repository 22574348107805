import React, { useState } from 'react';
import image from '../../assets/login.jpg'
import Cookies from 'js-cookie';
import GoogleIcon from '@mui/icons-material/Google';
import { CircularProgress } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './login.scss'
const Login = () => {
  const provider = new GoogleAuthProvider();
  const firebaseConfig = {
    apiKey: "AIzaSyBzUasS-CkrwTli9S2Wq5cGXdl-IGOk_G4",
    authDomain: "photography-store-70326.firebaseapp.com",
    databaseURL: "https://photography-store-70326-default-rtdb.firebaseio.com/",
    projectId: "photography-store-70326",
    storageBucket: "photography-store-70326.appspot.com",
    messagingSenderId: "907110427993",
    appId: "1:907110427993:web:ad2a1ad680cccc4210b048",
    measurementId: "G-BSRXJL5SBX"
  };

  const app = firebase.initializeApp(firebaseConfig);
  document.title = "Login"
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalid, setInvalid] = useState('');
    const [validating, setValidating] = useState(false);
    const [inputType, setInputType] = useState('password');

    // Function to toggle the input type
    function toggleInputType() {
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }
    const CLIENT_ID = "964015494828-2m2v0crrhbnn1c3kt1no5f5gre665fht.apps.googleusercontent.com"
    const handleSubmit = async (event) => {
        setValidating(true)
        event.preventDefault();
        try {
          const response = await fetch('https://api.cameracove.com/login', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
          });
    
          if (response.status == 200) {
            const data = await response.json();
            Cookies.set('auth', data.sessionCookie);
            window.location.href = "/dashboard"
          } else {
            const error = await response.json();
            setInvalid(error.error);
            setValidating(false)
          }
        } catch (error) {
          setInvalid(error.error);
          setValidating(false)
        }
      };
      const handleClick = async () => {
        const auth = getAuth(app);
        try {
          const res = await signInWithPopup(auth, provider);
          const user = res.user;
          user.getIdToken().then(function (idToken) {
            window.location.href = `/auth/google?token=${idToken}`
          }).catch(error => {
            console.log(error);
          });
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
      };
    
    return (
    <div className='login-wrapper'>
        <div className="login">
            <div className="login-left">
                <div className="login-header">
                    Welcome back!
                </div>
                <div className="login-subheader">
                    Welcome back! Please login below.
                </div>
                <div className="login-form">
                    <form onSubmit={handleSubmit}>
                        <div className="email">
                            <span className="field-type">Email</span>
                            <input type="email" name="email" placeholder='Enter email' className='login-text-input' value={email} onChange={(event) => setEmail(event.target.value)}/>
                        </div>
                        <div className="password">
                            <span className="field-type">Password</span>
                            <div className="view-password">
                              <input type={inputType} placeholder='Enter password' className='login-password-input' value={password} onChange={(event) => setPassword(event.target.value)}/><RemoveRedEyeIcon className='view-icon' onClick={toggleInputType}/>
                            </div>
                        </div>
                        {invalid != '' &&
                            <div className="error-text">
                               {invalid}
                            </div>
                        }
                        {validating === false &&
                          <input type="submit" value="Sign In" className='btn-large sign-submit gradient-bg'/>
                        }
                        {validating === true &&
                          <button className="btn-large gradient-bg sign-submit"><CircularProgress color="inherit" className='validating'/></button>
                        }
                    </form>
                    {/*
                      <div className="google-authentication" onClick={handleClick}>
                        <button className="btn-large gradient-bg-blue google-auth"><GoogleIcon /> Sign In With Google</button>
                      </div>
                    */}
                    
                </div>
                
                <div className="sign-up">
                        <div className="register">
                          <span className="new-user">Dont have an account?</span> <a href="/register" className='sign-up-text'>Sign Up</a>
                        </div>
                        <div className="forgot">
                          <span className="new-user">Need to reset your password?</span> <a href="/reset" className='sign-up-text'>Reset Password</a>
                        </div>
                </div>
            </div>
            <div className="login-right">
                <LazyLoadImage
                    alt="Login page image"
                    src={image} // use normal <img> attributes as props
                    className='login-image'
                />
            </div>
        </div>
    </div>
  )
}

export default Login