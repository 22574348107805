import React from 'react'
import './compare.scss'
import example from '../../../assets/product-example.jpeg'

const Comparison = (props) => {
  const items = props.item;
  const itemsLength = Object.values(props.item);
  const getProduct = (item, onsite) => {
    if (onsite === true) {
      window.location.href = `/product/us/${item.id}`
    } else {
      window.location.href = `/product/${item.id}`
    }
  }

  return (
    <div className="compare">
      <h2 className="compare-items">
        Compare Items
      </h2>
  
      <div className='compare-wrapper'>
        
        {items.length >= 1 &&
        <>
          {items.map((item, index) => (
            <div className="compare-card" key={index} onClick={() => getProduct(item, item.on_site)}>
              <div className="comparison-details">
                <div className="compare-item">
                  {item.item}
                </div>
                <div className="compare-price">
                  ${item.price}
                </div>
              </div>
              <div className="compare-image">
                            {item.image != "null" && !item.image.includes("imgur") &&
                                <>
                                    <img src={item.image} alt="compare-image-src" className="compare-image-src" />
                                </>
                            }
                            {item.image === "null" &&
                                <>
                                    <img src={example} alt="compare-image-src" className="compare-image-src" />
                                </>
                            }
                            {item.image != "null" && item.image.includes("imgur") &&
                                <img src={example} alt="compare-image-src" className="compare-image-src" />
                            }
              </div>
            </div>
          ))}
        </>
        }
      

      </div>
    </div>
  )
}

export default Comparison