import {useEffect} from 'react'
import './terms.scss'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='terms-wrapper'>
            <div className="terms">
                <div className="terms-header">
                    Terms and Conditions
                </div>
                <div className="terms-content bold">
                    Welcome to Camera Cove, a website that allows users to view and compare prices for used camera gear from various sources. By accessing or using the Site, you agree to be bound by these Terms and Conditions. If you do not agree to all the Terms, then you may not use the Site.
                    <br></br><br></br>
                    Please read these Terms carefully before using the Site. The Site is operated by Camera Cove and these Terms apply to all users of the Site, including users who are also contributors of content, information, and other materials or services on the Site.
                    <br></br><br></br>
                </div>
                <div className="terms-content">
                    The Site acts as an intermediary for users to view and compare prices for used camera gear from various sources. We do use affiliate links and by using this site you agree to the utilization of these links. The Company is not responsible for the actions or inactions of the end sellers or any issues that may arise during the purchase process. It is the responsibility of the client to handle any issues with the end seller directly. The Company is not responsible for the accuracy, completeness, or legality of the information provided on the Site or any damages resulting from the use of such information.
                    <br></br><br></br>

                    By using the Site, you acknowledge and agree that the Company is not responsible for any losses or damages that may arise from your interactions with the end sellers or from the purchase of used camera gear. You agree to use the Site at your own risk and the Company shall not be liable for any damages or losses that may arise from your use of the Site.
                    <br></br><br></br>
                    The Company reserves the right to modify these Terms at any time without prior notice. Your continued use of the Site after any such modifications constitutes your acceptance of the modified Terms. It is your responsibility to review these Terms regularly for any changes.
                    <br></br><br></br>
                    By using the Site, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into a binding agreement. If you are using the Site on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on behalf of that entity and that such entity agrees to indemnify the Company for any violations of these Terms.
                    <br></br><br></br>
                    These Terms constitute the entire agreement between you and the Company and govern your use of the Site, superseding any prior agreements between you and the Company. The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.
                    <br></br><br></br>
                    By accessing or using the Site, you agree to be bound by these Terms and the Company's Privacy Policy. If you have any questions about these Terms, please contact us at <span className="email">help@cameracove.com</span>.
                </div>
            </div>
        </div>
    )
}

export default Terms