import React from 'react'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import Header from '../components/welcome/Header'
import '../index.scss'

const Home = () => {
  document.title = "Camera Cove";
  return (
    <div className='home-page page'>
        <Navbar />
        <section className="header-section">
          <Header />
        </section>
        <Footer />
    </div>
  )
}

export default Home