import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Conversations from '../components/chat/Conversations'

const Messages = () => {
  return (
    <div className='conversation-page page'>
        <Navbar />
        <section className="header-section conversations">
            <Conversations />
        </section>
        <Footer />
    </div>
  )
}

export default Messages