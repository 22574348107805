import React from 'react'
import Register from '../components/Login/Register'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'
import Terms from '../components/terms/Terms'

const TermsConditions = () => {
    document.title = "Terms and Conditions"
  return (
    <div className='terms-wrapper'>
        <Navbar />
        <section className="header-section terms">
          <Terms />
        </section>
        <Footer />
    </div>
  )
}

export default TermsConditions