import { useState, useEffect } from 'react';
import example from '../../../assets/product-example.jpeg';
import { NavLink, useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material'; // Import the CircularProgress component
import './card.scss';
import Skeleton from '@mui/material/Skeleton';
import Like from '../Likes/Heart';
import ProductImage from './ProductImage';

const Card = (props) => {
  const [loading, setLoading] = useState(true); // Add a loading state
  const [imagesLoaded, setImagesLoaded] = useState([]); // Add an array to keep track of loaded images

  const show = props.showHeart;
  const products = props.products;
  const likes = props.likes;
  const heart = props.show;
  const dataPoint = props.endpoint;
  const navigate = useNavigate();

  console.log(products);

  async function handleProduct(product, type) {
    var id = product.id;
    if (type === true && product.product_id) {
      id = product.product_id;
    }
    else if (type === true && !product.product_id) {
      id = product.id;
    }
    await sessionStorage.setItem("scrollPosition", window.scrollY);
    await sessionStorage.setItem("category", dataPoint);
    if (type === true) {
      navigate(`/product/us/${id}`);
    } else {
      navigate(`/product/${id}`);
    }
  };

  useEffect(() => {
    setLoading(true); // Set loading to true when the component mounts
  }, []);

  return (
    <div className='product-card-wrapper'>
      {products.map((product, i) => (

        <div className="product-card" key={i} id={product.id} onClick={() => handleProduct(product, product.on_site)}>
            <ProductImage product={product} />
          {show === true &&
            <>
              {Object.values(likes).some(like => like.id === product.id) ? (
                <Like data={product} likes={likes} liked={true} onClick={e => e.stopPropagation()} />
              ) : (
                <Like data={product} likes={likes} liked={false} onClick={e => e.stopPropagation()} />
              )}

              {product.on_site === true &&
                <>
                  {Object.values(likes).some(like => like.id === product.product_id) ? (
                    <Like data={product} likes={likes} liked={true} onClick={e => e.stopPropagation()} />
                  ) : (
                    <Like data={product} likes={likes} liked={false} onClick={e => e.stopPropagation()} />

                  )}
                </>
              }

            </>
          }
          {product.average_price != product.price && likes && product.rating != "unknown" &&
            <>
              <div className="rating">
                {product.average_price != product.price &&
                  <>
                    <span className={product.rating}>{product.rating}</span>
                  </>
                }
              </div>
            </>
          }

          {product.on_site === true &&
            <>
              <div className="rating">
                <>
                  <span className={product.available.toLowerCase()}>{product.available}</span>
                </>
              </div>
            </>

          }

          {heart === true &&
            <>
              <Like data={product} liked={true} onClick={e => e.stopPropagation()} />
            </>
          }

          <div className="product-details">
            <div className="product-title">
              {product.on_site === true &&
                <>
                  {product.name}
                </>
              }
              {product.on_site === true && !product.name &&
                <>
                  {product.item}
                </>
              }
              {!product.on_site &&
                <>
                  {product.item}
                </>
              }
            </div>
            <div className="condition">
              {product.condition}
              {product.on_site === true &&
                <>
                  <br></br>
                  {product.notes}
                </>
              }
              {!product.on_site &&
                <>
                  <br></br>
                  {product.description}
                </>
              }
            </div>
            <div className="price">
              {product.price != null &&
                <>
                  ${product.price}
                </>
              }
              {product.price === null &&
                <>
                  Unknown Price
                </>
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;

