import React, { useState } from 'react';
import './subscription.scss';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Cookies from 'js-cookie';
const Subscription = () => {
  const [loading, setLoading] = useState(false); // State to track loading state of payment

  const handlePayment = async (amount, type, id) => {
    setLoading(true);

    amount = 2 * 100;

    try {
      const response = await fetch('https://api.cameracove.com/api/generate-payment-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
            Authorization: `${Cookies.get('auth')}`,
        },
        body: JSON.stringify({
          amount,
          currency: 'usd',
          type,
          id,
          returnUrl: 'http://localhost:3000/success', // Replace with your success page URL
        }),
      });

      const { paymentLink } = await response.json();
      window.location.href = paymentLink; // Redirect the user to the payment link
    } catch (error) {
      console.error('Payment link generation failed:', error);
      // Handle error and display error message to the user
    }

    setLoading(false);
  };

  return (
    <div className='subscription-wrapper'>
      <h3>Subscription Tiers</h3>
        <p>Choose a subscription tier that fits your needs! <b>Cancel anytime!</b></p>
      <div className="subscription-container">
        <div className="subscription-card">
          <h2 className="tier-title">Normal</h2>
          <div className="subtitle">
            <LoyaltyIcon className="loyalty-icon" /> 
            For our customers who do not need too much!
          </div>
          <div className="price-big">
            <div className="price">
              $5.00
            </div>
            <div className="per-month">
              per month
            </div>
          </div>
          <div className="subscribe-button">
            <button
              className="btn-large gradient-bg-blue subscribe-button-btn"
              onClick={() => handlePayment(500, 'normal', 'price_1NNyu0DhLlqkfS90ylqMdXKj')}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
          <ul className="tier-features">
            <li>Access to used camera gear marketplace</li>
            <li>Basic AI recommendations</li>
            <li>Limit to 5 AI requests per month</li>
          </ul>
        </div>
        <div className="subscription-card">
          <h2 className="tier-title gold">Gold</h2>
          <div className="subtitle">
            <LoyaltyIcon className="loyalty-icon" /> 
            For our customers who need a little more!
          </div>
          <div className="price-big">
            <div className="price">
              $10.00
            </div>
            <div className="per-month">
              per month
            </div>
          </div>
          <div className="subscribe-button">
            <button
              className="btn-large gradient-bg-blue subscribe-button-btn"
              onClick={() => handlePayment(1000, 'gold')}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
          <ul className="tier-features">
            <li>Access to used camera gear marketplace</li>
            <li>Enhanced AI recommendations</li>
            <li>Premium support</li>
            <li>Early access to new features</li>
            <li>Limit to 15 AI requests per month</li>
          </ul>
        </div>
        <div className="subscription-card">
          <h2 className="tier-title platinum">Platinum</h2>
          <div className="subtitle">
            <LoyaltyIcon className="loyalty-icon" /> 
            For our customers who need the most!
          </div>
          <div className="price-big">
            <div className="price">
              $15.00
            </div>
            <div className="per-month">
              per month
            </div>
          </div>
          <div className="subscribe-button">
            <button
              className="btn-large gradient-bg-blue subscribe-button-btn"
              onClick={() => handlePayment(1500, 'platinum')}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
          <ul className="tier-features">
            <li>Access to used camera gear marketplace</li>
            <li>Advanced AI recommendations</li>
            <li>Premium support</li>
            <li>Early access to new features</li>
            <li>Increased credits for AI usage</li>
            <li>Exclusive perks and discounts</li>
            <li>Limit to 50 AI requests per month</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
