import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import ProductUs from '../components/Product/ProductUs'

const ProductLocal = () => {
  return (
    <div className='product-us-page page'>
        <Navbar />
        <section className="header-section products">
            <ProductUs />
        </section>
        <Footer />
    </div>
  )
}

export default ProductLocal