import { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home.jsx';
import ProductView from './pages/ProductView.jsx';
import Products from './pages/Products.jsx';
import LoginPage from './pages/LoginPage.jsx';
import SignIn from './pages/SignIn.jsx';
import DashboardView from './pages/DashboardView.jsx'
import Reccomendations from './pages/Reccomendations.jsx'
import ArticlePost from './pages/ArticlePost.jsx';
import Verification from './pages/Verification.jsx';
import BlogWrite from './pages/BlogWrite.jsx';
import EditBlog from './pages/EditBlog.jsx'
import ResetPage from './pages/ResetPage.jsx'
import ErrorPage from './pages/ErrorPage.jsx'
import Cameras from './pages/Cameras.jsx';
import Lenses from './pages/Lenses.jsx';
import OurProducts from './pages/OurProducts.jsx';
import Accessories from './pages/Accessories.jsx';
import SearchReturned from './pages/SearchReturn.jsx';
import TermsConditions from './pages/TermsConditions.jsx';
import AboutPage from './pages/AboutPage.jsx';
import ContactPage from './pages/ContactPage.jsx';
import GalleryPage from './pages/GalleryPage.jsx'
import UploadPage from './pages/UploadPage.jsx'
import EditImage from './pages/EditImage.jsx'
import GoogleCallback from './components/Login/Google.jsx';
import LikesView from './pages/LikesView.jsx'
import Deals from './pages/Deals.jsx';
import SellerSell from './pages/SellerSell.jsx';
import SellerSignup from './pages/SellerSignup.jsx';
import SellerView from './pages/SellerView.jsx';
import ChatView from './pages/ChatView.jsx';
import Messages from './pages/Messages.jsx';
import ProductLocal from './pages/ProductLocal.jsx';
import SubscriptionPage from './pages/SubscriptionPage.jsx';
import Seller from './pages/Seller.jsx';
import AIPage from './pages/AIPage.jsx';
import PaymentChecker from './pages/PaymentChecker.jsx';

function App() {
  
  return (
<BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ai" element={<AIPage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/search" element={<SearchReturned />} />
        <Route path="/cameras" element={<Cameras />} />
        <Route path="/lenses" element={<Lenses />} />
        <Route path="/products/us" element={<OurProducts />} />
        <Route path="/deals" element={<Deals />} />
        <Route path="/accessories" element={<Accessories />} />
        <Route path="/suggestions" element={<Reccomendations />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignIn />} />
        <Route path="/reset" element={<ResetPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/write" element={<BlogWrite />} />
        <Route path="/conversations" element={<Messages />} />
        <Route path="/success" element={<PaymentChecker />} />
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/subscribe" element={<SubscriptionPage />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/dashboard" element={<DashboardView />} />
        <Route path="/account" element={<DashboardView />} />
        <Route path="/seller/dashboard/*" element={<Seller />} />
        <Route path="/likes" element={<LikesView />} />
        <Route path="/sell-product" element={<SellerSell />} />
        <Route path="/product/*" element={<ProductView />} />
        <Route path="/product/us/*" element={<ProductLocal />} />
        <Route path="/article/*" element={<ArticlePost />} />
        <Route path="/edit/*" element={<EditBlog />} />
        <Route path="/image/*" element={<EditImage />} />
        <Route path="/user/:id" element={<SellerView />} />
        <Route path="/chat/:id" element={<ChatView />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
        <Route path="/seller-signup" element={<SellerSignup />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
  </BrowserRouter>
  )
  
};

export default App;