import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import About from '../components/about/About'

const Accessories = () => {
  return (
    <div className='about-page page'>
        <Navbar />
        <section className="header-section about">
          <About />
        </section>
        <Footer />
    </div>
  )
}

export default Accessories