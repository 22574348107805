import * as React from "react";
import Cookies from 'js-cookie';
import { render } from "react-dom";
import c from "classnames";
import "./heart.scss";
import FavoriteIcon from '@mui/icons-material/Favorite';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export default class Like extends React.Component {
  state = {
    likeActive: this.props.liked,
    dislikeActive: false,
    toast: false,
    update: false,
    contents: '',

  };


  handleClose() {
    this.setState({toast: false})
  };

  closeToast = (event) => {
      event.stopPropagation();
      this.setState({toast: false})
    };
  setLike() {
    this.setState({
      likeActive: !this.state.likeActive,
    });
  }
  handleLike = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.likeActive == false) {
      try {
        this.setState({
          likeActive: !this.state.likeActive,
        });
        const payload = {};

        if (this.props.data.on_site) {
          payload.condition = this.props.data.condition || "";
          payload.description = this.props.data.notes || "";
          payload.id = this.props.data.product_id || "";
          payload.image = this.props.data.imageUrls[0] || "";
          payload.item = this.props.data.name || "";
          payload.price = this.props.data.price || "";
          payload.type = this.props.data.type || "";
          payload.url =  `/products/us/${this.props.data.product_id}` || "";
          payload.uid = this.props.data.uid || "";
          payload.onsite = true;
        } else {
          payload.condition = this.props.data.condition || "";
          payload.description = this.props.data.description || "";
          payload.id = this.props.data.id || "";
          payload.image = this.props.data.image || "";
          payload.item = this.props.data.item || "";
          payload.price = this.props.data.price || "";
          payload.type = this.props.data.type || "";
          payload.url = this.props.data.url || "";
          payload.uid = this.props.data.uid || "";
          payload.onsite = false;
        }

        const response = await fetch('https://api.cameracove.com/like', {
          method: 'POST',
          headers: {
            Authorization: `${Cookies.get('auth')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        const data = await response.json();
        this.setState({update: true})
        this.setState({toast: true})
        this.setState({open: true})
        this.setState({contents: "This item has been liked!"})
      } catch (error) {
        console.error(error);
      }
    }else {
      this.setState({
        likeActive: false,
      });
      let url = "";

      if (this.props.data.on_site) {
        if (this.props.data.product_id === undefined) {
          url = `https://api.cameracove.com/like/${this.props.data.id}`;
        } else {
          url = `https://api.cameracove.com/like/${this.props.data.product_id}`;
        }
      } else {
        url = `https://api.cameracove.com/like/${this.props.data.id}`;
      }

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `${Cookies.get('auth')}`,
          'Content-Type': 'application/json',
        },
      });


      if (response.ok) {
        this.setState({update: true})
        this.setState({toast: true})
        this.setState({open: true})
        this.setState({contents: "The like has been removed."})
        
      } else {
          console.error('Error removing like');
      }
    }
    
  }

  render() {
    const { data } = this.props;
    return (
      <>
        <div onClick={(event) => this.handleLike(event)} className="external-link">
          <FavoriteIcon fontSize="large" id="heart-icon" className={c({ ["external-icon-active"]: this.state.likeActive })} />
        </div>
        <Snackbar open={this.state.toast} autoHideDuration={3000} autoHide={true} onClose={() => this.handleClose()}>
            <Alert onClose={this.closeToast} severity="success" sx={{ width: '100%' }}>
              {this.state.contents}
            </Alert>
        </Snackbar>
      </>
    );
  }
}