import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import Skeleton from '@mui/material/Skeleton';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography, 
    Box, 
    Avatar, Dialog, DialogTitle, DialogContent, DialogActions, TextField
  } from '@mui/material';

import Cookies from 'js-cookie';
import Loader from '../loader/Loader';
import EditIcon from '@mui/icons-material/Edit';

const SellerDetails = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState(null);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isSeller, setIsSeller] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const[item, setItem] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sellerProducts, setSellerProducts] = useState([]);
    const [productLoader, setProductLoader] = useState(false);

    const handleProductSelect = (event) => {
        const selectedItemId = event.target.value;
        const selectedItem = products.find((item) => item.itemId === selectedItemId);
        setSelectedProduct(selectedItemId);
        setItem(selectedItem);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const reviewParam = params.get('review');
        const messageParam = params.get('message');
    
        if (reviewParam === 'true') {
            setOpenReviewModal(true);
        } else if (messageParam === 'true') {
            handleCreateChat().then(() => {
                setOpenChat(true);
            });
        }
      }, [location, seller]);
  
      useEffect(() => {
        setLoading(true);
      
        const fetchSellerDetails = () => {
          return new Promise((resolve, reject) => {
            fetch(`https://api.cameracove.com/seller/${id}`, {
              headers: {
                Authorization: `${Cookies.get('auth')}`,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                setSeller(data.seller);
                setIsSeller(data.isSeller);
                document.title = `${data.seller.name} | Seller`;
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
          });
        };
      
        const getSellerProducts = () => {
          return new Promise((resolve, reject) => {
            fetch(`https://api.cameracove.com/seller-listed-items/${id}`, {
              headers: {
                Authorization: `${Cookies.get('auth')}`,
              },
            })
              //if response is not ok, redirect to 404 page
              .then((response) => {
                if (response.ok) {
                  return response;
                } else {
                  setSellerProducts(null);
                }
              })
              .then((response) => response.json())
              .then((data) => {
                setSellerProducts(data.items);
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
          });
        };
      
        Promise.all([fetchSellerDetails(), getSellerProducts()])
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      
      }, [id]);
      

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
    setRating(0);
    setReview('');
  };

  const handleCloseChat = () => {
    setOpenChat(false);
    };

   const handleOpenChat = () => {
        setLoading(true);
        setOpenChat(true);
        handleMessage();
    };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleCreateChat = async () => {
    setSelectedProduct('No product');
    try {
        const response = await fetch(`https://api.cameracove.com/seller-listed-items/${id}`, {
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            }
        });
        const data = await response.json();
        setProducts(data.items);
        setOpenChat(true);
    } catch (error) {
        console.error(error);
    }
    };

    const handleMessage = async () => {
        try {
          const imageUrl = item.imageUrls[0]; // Get the first image URL from the selected product
          const itemName = item.name;
          const itemPrice = item.price;
          const itemId = item.product_id;
          const recipientId = id;
      
          const response = await fetch('https://api.cameracove.com/create-conversation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('auth'),
            },
            body: JSON.stringify({
              recipientId,
              itemImage: imageUrl,
              itemName,
              itemPrice,
              itemId
            }),
          });
      
          const data = await response.json();
          const { chatUrl } = data;
          window.location.href = chatUrl;
        } catch (error) {
          console.error(error);
        }
      };
      
      

  const handleReviewSubmit = async () => {
    // input validation 
    if (rating === 0) {
        alert('Please select a rating.');
        return;
    }
    if (review === '') {
        alert('Please enter a review.');
        return;
    }
    // Send the review to the API endpoint
    const response = await fetch('http://localhost:3001/submit-review', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${Cookies.get('auth')}`,
      },
      body: JSON.stringify({
        sellerId: id,
        rating,
        review,
      }),
    });

    if (response.ok) {
        setSuccess(true)
    } else {
        setError(true)
    }

    //handleCloseReviewModal();
  };

  if (!seller) {
    return null; // You can show a loading spinner or placeholder here
  }

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalfIcon key={fullStars} />);
    }

    return stars;
  };

  return (
    <>
    
    <div className="seller-display-wrapper">
    {loading === true &&

            <Loader />
    }
        {loading === false &&
        <>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Avatar src={seller.profilePicture} alt={seller.name} sx={{ width: 100, height: 100 }} />
        <Typography variant="h4">{seller.name}</Typography>
        <Box display="flex" alignItems="center">
          {renderStars(seller.rating)}
          <Typography variant="body1" sx={{ marginLeft: 1 }}>
            {seller.rating}
          </Typography>
        </Box>
        <Typography variant="body2">{seller.email}</Typography>
        <Typography variant="body2">{seller.phoneNumber}</Typography>
        <Typography variant="body2">{seller.description}</Typography>
        <br />
        {!isSeller &&
        <div className="actions">
            <button className="btn btn-primary gradient-bg-blue" onClick={handleCreateChat}>Message</button>
            <button className="btn btn-primary gradient-bg" onClick={handleOpenReviewModal}>
            Review
            </button>
        </div>
        }
        
      </Box>
        
        {seller.reviews && seller.reviews.length > 0 &&
        <div className='seller-products'>
            <h2>Reviews</h2>
            <div className="reviews">
                {Object.values(seller.reviews).map((review) => (
            <div className="review-wrapper">
                <div className="review-header">
                <div className="reviewer-details">
                    <img src={review.reviewerProfileImage} alt="Profile picture" className="profile-image" />
                    <div className="name">
                        <h2>{review.reviewerName}</h2>
                    </div>
                </div>
                <Box display="flex" alignItems="center">
                    {renderStars(review.rating)}
                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                    {review.rating}
                    </Typography>
                </Box>
                <div className="review-description">
                    <p className="review-text">{review.review}</p>
                </div>
                </div>
            </div>
            ))}
                </div>
                </div>
        }
        {productLoader &&
            //use skeleton for loader 
            <div className="seller-products">
                <h2>Products</h2>
                {Array(3).fill().map((item, index) => (
                    <>
                    <div className="products-wrapper">
                        <div className="product-wrapper">
                            <div className="product-image">
                                <Skeleton variant="rectangular" width={210} height={118} />
                            </div>
                            <div className="product-details">
                                <div className="product-name">
                                    <Skeleton variant="text" width={210} height={118} />
                                </div>
                                <div className="product-price">
                                    <Skeleton variant="text" width={210} height={118} />
                                </div>
                                <div className="product-description">
                                    <Skeleton variant="text" width={210} height={118} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </>
                )
                )}
                
            </div>
        }
        {!productLoader &&
            <div className="seller-products">
                <h2>Products</h2>
                <div className="products-wrapper">
                    {sellerProducts === null && 
                        <div className="no-products">
                            <p>This seller has no products listed.</p>
                        </div>
                    }
                    {sellerProducts != null && sellerProducts.map((product) => (
                        <>
                        <div className="product-item">
                            <div className="product-wrapper" onClick={
                                () => {
                                    window.location.href = `/product/us/${product.product_id}`;
                                }
                            }>
                                <div className="product-image">
                                    <img src={product.imageUrls[0]} alt="Product" className='product-image-image' />
                                </div>
                                <div className="product-details">
                                    <div className="product-name">
                                        <h2>{product.name}</h2>
                                    </div>
                                    <div className="product-price">
                                        <p>${product.price}</p>
                                    </div>
                                    <div className="product-description">
                                        <p>Availability: {product.available}</p>
                                    </div>
                                </div>
                            </div>
                            {isSeller === true &&
                                <div className="edit-product">
                                    <EditIcon className='edit-icon' onClick={
                                        () => {
                                            window.location.href = `/edit-product/${product.product_id}`;
                                        }
                                    } />
                                </div>
                            }
                        </div>
                        
                        </>
                    ))}
                </div>
            </div>

        
        }
        {/* Review Modal */}
      <Dialog open={openReviewModal} onClose={handleCloseReviewModal} className='modal'>
        <div className="modal-content">
            <DialogContent>
                <h2>Write a Review</h2>
                    <div className='modal-prompts'>
                    <div className="rating-content">
                        <Typography variant="body1" fontSize={20}>Rating:</Typography>
                        <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center">
                                {[1, 2, 3, 4, 5].map((value) => (
                                <StarIcon fontSize='large' key={value} sx={{ cursor: 'pointer' }} color={rating >= value ? 'primary' : 'inherit'} onClick={() => setRating(value)} />
                                ))}
                            </Box>
                        </Box>
                    </div>
                    <div className="input-rating">
                        <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            label="Review"
                            value={review}
                            onChange={handleReviewChange}
                            fullWidth
                        />
                    </div>
                </div>                
                
            </DialogContent>
            <div className='bottom-modal-actions'>
                <Button onClick={handleCloseReviewModal}>Cancel</Button>
                <Button onClick={handleReviewSubmit} variant="contained">
                    Submit
                </Button>
            </div>
        </div>
        {success == true &&
            <div className="success">
                <div className="success-text">Review submitted!</div>
            </div>
        }
        {error == true &&
            <div className="error">
                <div className="error-text">Error submitting review.</div>
            </div>
        }
      </Dialog>


      {/* Chat Modal */}
      <Dialog open={openChat} onClose={handleCloseChat} className='modal-chat'>
        <div className="modal-content">
            <DialogContent>
            <h2 className='title'>Select Which Product to Chat About</h2>
            <FormControl>
                <InputLabel id="product-select-label">Product</InputLabel>
                <Select
                labelId="product-select-label"
                id="product-select"
                value={selectedProduct}
                width={500}
                style={
                    {
                        width: '10rem',
                    }
                }
                onChange={handleProductSelect}
                >
                {products.map((item) => (
                    <MenuItem key={item.itemId} value={item.itemId}>
                    {item.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </DialogContent>
            <div className='bottom-modal'>
                <Button onClick={handleCloseChat}>Cancel</Button>
                <Button onClick={handleOpenChat} variant="contained">
                    Create Chat
                </Button>
            </div>
        </div>
        {error && (
            <div className="error">
            <div className="error-text">Error creating conversation</div>
            </div>
        )}
        </Dialog>
        </>
        
        }
      
      

      

      
    </div>
    </>
  );
};

export default SellerDetails;
