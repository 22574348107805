import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button } from '@mui/material';
import ProductViewGraph from '../dashboard/ProductViewGraph';
import './modal.scss'

const EditProductModal = ({ productId, isOpen, onClose, sellerId }) => {
  const [product, setProduct] = useState({});
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [condition, setCondition] = useState('');
  // Add more state variables for other product details

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`https://api.cameracove.com/product/us/${productId}`);
        const data = await response.json();
        setProduct(data.product);
        setName(data.product.name);
        setPrice(data.product.price);
        setCondition(data.product.condition);
        // Set other state variables based on fetched product data
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (isOpen) {
      fetchProduct();
    }
  }, [isOpen, productId]);

  const handleSave = async () => {
    try {
      // Create a new product object with the updated details
      const updatedProduct = {
        ...product,
        name,
        price,
        condition,
        // Add other updated product details
      };

      // Make a PUT request to update the product
      await fetch(`https://api.cameracove.com/edit-post/${productId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProduct),
      });

      // Close the modal and perform any other necessary actions
      onClose();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modal-container">
        <ProductViewGraph sellerId={productId} />
        <h2>Edit Product</h2>
        <div className="inputs">
            <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
            <TextField
            label="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            />
            <TextField
            label="Condition"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            />
        </div>
        
        {/* Add more TextField components for other product details */}
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default EditProductModal;
