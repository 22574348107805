import { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Search from '../search/Search'
import Slider from '@mui/material/Slider';
import './products.scss'
import Card from './Card/Card'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Loader from '../loader/Loader';
import Connection from '../error/Connection';
import Results from '../error/Results';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';


const SearchAll = () => {
  const [brand, setBrand] = useState('');
  const [store, setStore] = useState('');
  const [sort, setSort] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [heart, setHeart] = useState(true);
  const [error, setError] = useState(false);
  const [price, setPrice] = useState(false);
  const [filter, setFilter] = useState(false);
  const [minPrice, setMinPrice] = useState(50);
  const [maxPrice, setMaxPrice] = useState(8000);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.get('category');
  const query = params.get('q');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < 480);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      setIsSmallScreen(screenWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);
  useEffect(() => {
    setLoading(true);
    Promise.all([fetchData(), fetchLikes()]).then(([dataResponse, likesResponse]) => {
      // Check if both API calls have returned a status
      if (dataResponse.status && likesResponse.status) {
        // Set the loading state to false if both API calls have returned a status
        setLoading(false);
      }
    }).catch((error) => {
      console.error(error);
    });
    
    async function fetchData() {
      try {
        const response = await fetch(`https://api.cameracove.com/search?category=${category}&query=${query}`);
        const data = await response.json();
        setProducts(data.results);
        return response;
      } catch (error) {
        setError(true);
      }
    }
    
    async function fetchLikes() {
      try {
        const response = await fetch(`https://api.cameracove.com/likes`, {
            headers: {
                Authorization: `${Cookies.get('auth')}`,
            }
        });
        try {
            const likes = await response.json();
            setLikes(likes);
        }catch (error) {
            if (response.length == undefined) {
                setHeart(true)
            }
        }
        if (response.status != 200) {
            setHeart(false);
        }
        return response;
      } catch (error) {
        setError(true)
      }
    }
    
  }, [page]);
  useEffect(() => {
    if (products.length > 0) {
        const savedMinPrice = sessionStorage.getItem('minPrice');
        const savedMaxPrice = sessionStorage.getItem('maxPrice');
        const savedBrand = sessionStorage.getItem('brand');
        const savedSort = sessionStorage.getItem('sort');
        const savedStore = sessionStorage.getItem('store');

        if (savedMinPrice && savedMaxPrice) {
            setMinPrice(parseInt(savedMinPrice));
            setMaxPrice(parseInt(savedMaxPrice));
            setPrice(true);
            setFilteredProducts(products.filter(product => product.price >= minPrice && product.price <= maxPrice));
        }

        if (savedBrand) {
            setBrand(savedBrand);
            setFilteredProducts(products.filter(product => product.brand === savedBrand));
            setFiltered(true);
            setShow(false);
        }

        if (savedSort) {
            setSort(savedSort);
            if (savedSort === "high") {
                sortByPriceHighToLow();
            } else {
                sortByPriceLowToHigh();
            }
        }

        if (savedStore) {
            setStore(savedStore);
            setFilteredProducts(filteredProducts.filter(product => product.store === savedStore));
            setFiltered(true)
        }
    }
}, [products])

  const marks = [
    {
      value: 50,
      label: '$50',
    },
    {
      value: 1500,
      label: '$1500',
    },
    {
      value: 3000,
      label: '$3000',
    },
    {
      value: 4500,
      label: '$4500',
    },
    {
      value: 6000,
      label: '$6000',
    },
    {
      value: 8000,
      label: '$8,000',
    },
  ];

  const handlePriceChange = (event, newValue) => {
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
    setPrice(true);
    sessionStorage.setItem('minPrice', newValue[0]);
    sessionStorage.setItem('maxPrice', newValue[1]);
    if (sort != '' || store != '' || brand != '') {
        setFilteredProducts(filteredProducts.filter(product => product.price >= minPrice && product.price <= maxPrice));
    }
    else {
        setFilteredProducts(products.filter(product => product.price >= minPrice && product.price <= maxPrice));
    }
    setFiltered(true);
};

const handleSearchResults = (results) => {
    setSearchResults(results);
    setSearch(true);
    setShow(false);
}
const handleChange = (event) => {
    const selectedBrand = event.target.value;
    setBrand(selectedBrand);
    sessionStorage.setItem('brand', selectedBrand);
    if (selectedBrand) {
        if (sort != '' || store != '' || price == true) {
            setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand));
            setFiltered(true);
        } else {
            setFilteredProducts(products.filter(product => product.brand === selectedBrand));
            setFiltered(true);
        }
        
    }

};

const handleSort = (event) => {
    const method = event.target.value;
    setSort(method);
    sessionStorage.setItem('sort', method);
    if (method == "high") {
        sortByPriceHighToLow();
    }
    if (method == "low") {
        sortByPriceLowToHigh();
    }
};

function sortByPriceLowToHigh() {
    if (brand != '' || store != '' || price == true) {
        const newFilter = filteredProducts.sort((a, b) => a.price - b.price)
        setFilteredProducts(newFilter);
        setFiltered(true);

    }
    else {
        setFilteredProducts(products.sort((a, b) => a.price - b.price));
        setFiltered(true);

    }
    sessionStorage.setItem("sort", "low");
}

function sortByPriceHighToLow() {
    if (brand != '' || store != '' || price == true) {
        const newFilter = filteredProducts.sort((a, b) => b.price - a.price)
        setFilteredProducts(newFilter);
    }
    else {
        setFilteredProducts(products.sort((a, b) => b.price - a.price));
    }
    setFiltered(true);

    sessionStorage.setItem("sort", "high");
}

const handleStore = (event) => {
    const selectedStore = event.target.value;
    setStore(selectedStore);
    // Check if there are already filtered products
    if (sort != '' || brand != '' || price == true) {
        setFilteredProducts(filteredProducts.filter(product => product.store === selectedStore));
    } else {
        // If there aren't, filter the original list of products by store
        setFilteredProducts(products.filter(product => product.store === selectedStore));
        setFiltered(true);
    }

    sessionStorage.setItem("store", selectedStore);
};

const resetData = () => {
    setFiltered(false);
    setSearch(false);
    setSearchResults([]);
    setBrand('');
    setStore('');
    setMinPrice(50);
    setMaxPrice(8000);
    setSort('');
    setFilteredProducts([]);
    sessionStorage.clear();
}
  function loadMore() {
    setPage(page + 1);
    scrollPos.current = window.scrollY;

    if (page.length < 50) {
      setShow(false)
    }
  }

  return (
    <div className='products-wrapper-main'>
      {loading == false && error == false &&
        <>
          <div className="products-header">
            <span className="view">Search</span> <span className="products">Products</span>
          </div>
          <div className="search-wrapper-products">
            <div className="search">
              <div className="search-item">
                <Search placeholder={query} value={true} category={category} onSearchResults={handleSearchResults} />
              </div>
              <div className="filter-on">
                {!filter && isSmallScreen &&
                  <div className="toggle-filter" onClick={() => setFilter(true)}>
                    <FilterAltIcon className='filter-icon' fontSize='large' />
                  </div>
                }
                {filter && isSmallScreen &&
                  <div className="toggle-filter" onClick={() => setFilter(false)}>
                    <FilterAltOffIcon className='filter-icon' fontSize='large' />
                  </div>
                }
              </div>
            </div>
            {!filter && !isSmallScreen &&
              <>
                <div className="filter-items" style={{ display: 'none' }}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brand}
                        label="Brand"
                        onChange={handleChange}
                      >
                        <MenuItem value={'Sony'}>Sony</MenuItem>
                        <MenuItem value={'Canon'}>Canon</MenuItem>
                        <MenuItem value={'Nikon'}>Nikon</MenuItem>
                        <MenuItem value={'Leica'}>Leica</MenuItem>
                        <MenuItem value={'Fuji'}>Fuji</MenuItem>
                        <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                        <MenuItem value={'Tamron'}>Tamron</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brand}
                        label="Brand"
                        onChange={handleChange}
                      >
                        <MenuItem value={'Sony'}>Sony</MenuItem>
                        <MenuItem value={'Canon'}>Canon</MenuItem>
                        <MenuItem value={'Nikon'}>Nikon</MenuItem>
                        <MenuItem value={'Leica'}>Leica</MenuItem>
                        <MenuItem value={'Fuji'}>Fuji</MenuItem>
                        <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                        <MenuItem value={'Tamron'}>Tamron</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items store">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Store</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={store}
                        label="Store"
                        onChange={handleStore}
                      >
                        <MenuItem value={'Fred Miranda'}>Fred Miranda</MenuItem>
                        <MenuItem value={'Reddit'}>Reddit</MenuItem>
                        <MenuItem value={'Ebay'}>eBay</MenuItem>
                        <MenuItem value={'Keh'}>Keh</MenuItem>
                        <MenuItem value={'Adorama'}>Adorama</MenuItem>

                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items store">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sort}
                        label="Sort"
                        onChange={handleSort}
                      >
                        <MenuItem value={'high'}>High to Low</MenuItem>
                        <MenuItem value={'low'}>Low to High</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-price">
                  <Slider
                    aria-label="Always visible"
                    defaultValue={800}
                    max={8000}
                    step={100}
                    marks={marks}
                    style={{ 'color': '#FA8071' }}
                    valueLabelDisplay="on"
                    onChange={handlePriceChange}
                    value={[minPrice, maxPrice]}
                  />
                </div>
              </>
            }

            {filter && isSmallScreen &&
              <>
                <div className="filter-items" style={{ display: 'block' }}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brand}
                        label="Brand"
                        onChange={handleChange}
                      >
                        <MenuItem value={'Sony'}>Sony</MenuItem>
                        <MenuItem value={'Canon'}>Canon</MenuItem>
                        <MenuItem value={'Nikon'}>Nikon</MenuItem>
                        <MenuItem value={'Leica'}>Leica</MenuItem>
                        <MenuItem value={'Fuji'}>Fuji</MenuItem>
                        <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                        <MenuItem value={'Tamron'}>Tamron</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brand}
                        label="Brand"
                        onChange={handleChange}
                      >
                        <MenuItem value={'Sony'}>Sony</MenuItem>
                        <MenuItem value={'Canon'}>Canon</MenuItem>
                        <MenuItem value={'Nikon'}>Nikon</MenuItem>
                        <MenuItem value={'Leica'}>Leica</MenuItem>
                        <MenuItem value={'Fuji'}>Fuji</MenuItem>
                        <MenuItem value={'Manfrotto'}>Manfrotto</MenuItem>
                        <MenuItem value={'Tamron'}>Tamron</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items store">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Store</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={store}
                        label="Store"
                        onChange={handleStore}
                      >
                        <MenuItem value={'Fred Miranda'}>Fred Miranda</MenuItem>
                        <MenuItem value={'Reddit'}>Reddit</MenuItem>
                        <MenuItem value={'Ebay'}>eBay</MenuItem>
                        <MenuItem value={'Keh'}>Keh</MenuItem>
                        <MenuItem value={'Adorama'}>Adorama</MenuItem>

                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-items store">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sort}
                        label="Sort"
                        onChange={handleSort}
                      >
                        <MenuItem value={'high'}>High to Low</MenuItem>
                        <MenuItem value={'low'}>Low to High</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="filter-price">
                  <Slider
                    aria-label="Always visible"
                    defaultValue={800}
                    max={8000}
                    step={100}
                    marks={marks}
                    style={{ 'color': '#FA8071' }}
                    valueLabelDisplay="on"
                    onChange={handlePriceChange}
                    value={[minPrice, maxPrice]}
                  />
                </div>
              </>
            }
          </div>
          {filtered == false && loading == false &&
                        <>
                            {search == false &&
                                <div className="products-all">
                                    <Card showHeart={heart} products={products} likes={likes} />
                                </div>
                            }
                            {search == true &&
                                <div className="products-all">
                                    <Card showHeart={heart} products={searchResults} likes={likes} />
                                </div>
                            }
                        </>
                    }

                    {filtered == true &&
                        <div className="products-all">
                            <Card showHeart={heart} products={filteredProducts} likes={likes} />
                        </div>
                    }


          {show == true &&
            <div className="load-more">
              <button onClick={loadMore} className='btn-large gradient-bg'>Show more</button>
            </div>
          }
        </>
      }
      {loading == true &&
        <Loader />
      }
      {error == true &&
        <Connection />
      }
      {products.length == 0 && loading==false &&
        <Results />
      }
      {filtered == true &&
        <div className="reset-filters">
          <button className="btn-large gradient-bg reset-button" onClick={resetData}>Clear Filters</button>
        </div>
      }
    </div>
  )
}

export default SearchAll