import React from 'react'
import Login from '../components/Login/Login.jsx'
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from '../components/footer/Footer.jsx'

const LoginPage = () => {
  return (
    <div className='login-wrapper'>
        <Navbar />
        <section className="header-section login">
          <Login />
        </section>
        <Footer />
    </div>
  )
}

export default LoginPage