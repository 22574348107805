import React from 'react'
import './error.scss'
import { useNavigate } from "react-router-dom";

const Connection = () => {
    const navigate = useNavigate()
  return (
    <div className='error-wrapper'>
        <div className="error-content">
            <div className="error-header">
                ERROR
            </div>
            <div className="sub-text">
                Error Fetching Data!
            </div>
            <div className="below-sub">
                There was an issue fetching the data. Please try again later.
            </div>
            <div className="back-home">
                <button className="btn-large gradient-bg back-home-btn" onClick={() => navigate('/')}>Back Home</button>
            </div>
        </div>
        
    </div>
  )
}

export default Connection