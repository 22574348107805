import {useState} from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import './article.scss'
const Article = (props) => {
    const posts = Object.values(props.data) // Make sure posts is an array
    const endpoint = props.edit
    const type = props.type
    const navigate = useNavigate()
    const [imagesLoaded, setImagesLoaded] = useState([]); // Add an array to keep track of loaded images


    function getPost(postId) {
        if (endpoint == true) {
            navigate(`/edit/${postId}`)
        }
        if (type == "image") {
            navigate(`/image/${postId}`)
        }
        if (endpoint == false) {
            navigate(`/article/${postId}`)
        }
    }

    const handleImageLoad = (postId) => {
        setImagesLoaded((prevImagesLoaded) => [...prevImagesLoaded, postId]); // Add the productId to the array of loaded images
    };

    const isImageLoaded = (postId) => {
        return imagesLoaded.includes(postId); // Check if the image with the productId is already loaded
    };

    return (
       <div className="article-wrapper-post">
        {posts.map(post => (
            <div className="article" key={post.id} onClick={() => getPost(post.id)}>
                <div className='article-wrapper'>
                    <div className="article-image">
                        {!isImageLoaded(post.id) && (
                            <Skeleton
                                variant="rectangular"
                                className="side-image"
                            />
                        )}
                        {post.updatedImage != "null" && !post.updatedImage.includes("imgur") && (
                            <img
                                src={post.updatedImage}
                                alt="product image"
                                className="side-image"
                                style={{ display: isImageLoaded(post.id) ? 'block' : 'none' }}
                                onLoad={() => handleImageLoad(post.id)}
                            />
                        )}
                   </div>
                    <div className="article-contents">
                        <div className="article-header">
                            <div className="profile-picture">
                                <img src={post.profilePicture} alt="profile-picture" className='profile-picture-image' />
                            </div>
                            <div className="article-information">
                                <div className="name">{post.username}</div>
                                <div className="date">{post.date}</div>
                            </div>
                        </div>
                        <div className="article-title">
                            {post.title}
                        </div>
                        <div className="article-description">
                            {post.message}
                        </div>
                    </div>
                    
                </div>
            </div>
        ))}
       </div>
    );
}

export default Article