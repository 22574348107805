import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

const ProductViewGraph = ({ sellerId }) => {
  const [viewData, setViewData] = useState([]);

  useEffect(() => {
    const fetchViewData = async () => {
      try {
        const response = await fetch(`https://api.cameracove.com/seller/view-count/${sellerId}`);
        const data = await response.json();
        setViewData(data.viewData);
      } catch (error) {
        console.error('Error fetching view count data:', error);
      }
    };
    fetchViewData();
  }, [sellerId]);

  // Extract the dates and counts from the view data
  const dates = viewData.map((data) => data.date);
  const counts = viewData.map((data) => data.count);

  // Define the chart data
  const chartData = {
    labels: dates,
    datasets: [
      {
        label: 'Daily View Count',
        data: counts,
        backgroundColor: '#0D4C73', // Set the bar color
      },
    ],
  };

  // Define the chart options
  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM dd', // Format for displaying day
          },
        },
        display: true,
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'View Count',
        },
      },
    },
  };

  return (
    <div className="graph-wrapper">
      <h2>Product View Count Graph</h2>
      {viewData.length > 0 ? (
        <Bar data={chartData} options={chartOptions} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductViewGraph;